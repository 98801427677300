import React from 'react'
import {
  VehicleSheetDTO,
  VehicleSheetOverLoad,
  defaultVehicleDTO,
  defaultVehicleOverload,
} from 'models'
import { VehicleSheet } from 'components'
import { useAlert } from 'contexts'
import { formatDatesInObject } from 'utils/helpers/format'
import useFullScreenModal from './useFullScreenModal'
import { useGetVehicle } from './queries'
import useLoading from './useLoading'

const useVehicleModal = () => {
  const { setOpenModal, DisplayedFullScreenModal } = useFullScreenModal()

  const VehicleModal = ({ id, onClose }: { id: string; onClose: () => void }) => {
    const { displayAlert } = useAlert()
    const { Loading } = useLoading()

    const {
      data: vehicleData,
      isLoading,
      error: getError,
      refetch,
    } = useGetVehicle({ vehicleId: id })

    const currentVehicleDTO = vehicleData || defaultVehicleDTO
    const currentVehicleFormattedDates = formatDatesInObject<VehicleSheetDTO, VehicleSheetOverLoad>(
      currentVehicleDTO,
    )
    const currentVehicle = { ...defaultVehicleOverload, ...currentVehicleFormattedDates }

    // FIXME: Avoid prop drilling
    const [isCarouselFull, setIsCarouselFull] = React.useState<boolean>(false)

    React.useEffect(() => {
      if (id) {
        refetch()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    React.useEffect(() => {
      if (getError?.message) {
        displayAlert('Erreur lors du chargement des véhicules', 'error')
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getError])

    return (
      <DisplayedFullScreenModal onClose={onClose}>
        {isLoading ? (
          <Loading />
        ) : (
          <VehicleSheet
            vehicle={currentVehicle}
            isCarouselFull={isCarouselFull}
            setIsCarouselFull={setIsCarouselFull}
          />
        )}
      </DisplayedFullScreenModal>
    )
  }

  return {
    setOpenModal,
    VehicleModal,
  }
}

export default useVehicleModal
