import { FutureSaleVehicleOverload } from 'models'

type FutureSaleVehiclesPropertiesToDisplay = Pick<
  FutureSaleVehicleOverload,
  | 'numeroOrdre'
  | 'immatriculation'
  | 'dossierRefVendeur'
  | 'estimation'
  | 'genre'
  | 'typeComm'
  | 'couleur'
  | 'date1MEC'
  | 'kilomReel'
  | 'cotePerso'
  | 'coteKm'
  | 'prixRetire'
  | 'reserve'
  | 'percentCP'
  | 'percentCK'
  | 'tva'
  | 'observationsVendeur'
  | 'nombrePassage'
>
type FutureSaleVehiclesTableLabels = Record<keyof FutureSaleVehiclesPropertiesToDisplay, string>

const futureSaleVehiclesTableLabels: FutureSaleVehiclesTableLabels = {
  // assign "undefined" to property if you don't want it to appear in data table
  numeroOrdre: 'Ord.',
  dossierRefVendeur: 'Réf.',
  immatriculation: 'Immat',
  genre: 'Ge',
  typeComm: 'Type Commercial',
  couleur: 'Coul',
  date1MEC: 'MEC',
  kilomReel: 'KM',
  cotePerso: 'CP',
  coteKm: 'CK',
  prixRetire: 'Ret.',
  nombrePassage: 'NP',
  reserve: 'Reserve',
  estimation: 'Est.',
  percentCP: '%CP',
  percentCK: '%CK',
  tva: 'TVA',
  observationsVendeur: 'Observat°',
}

export { futureSaleVehiclesTableLabels }
