import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { ApiPhotosResponse, currentQueryService } from 'utils/api/queries'

const prioritizePhotos = (response: ApiPhotosResponse | undefined): string[] => {
  const photo = response?.data?.PHOTO ?? []
  const ex = response?.data?.EX ?? []
  const secondChoicePhotos = photo.concat(ex)
  const result = response?.data?.['2D'] ?? secondChoicePhotos
  return result
}

const useGetPhotos = (immat: string): string[] => {
  const query = useQuery<ApiPhotosResponse, Error>({
    queryKey: ['getPhotos', immat],
    queryFn: () => currentQueryService.getPhotos(immat),
    enabled: false,
  })

  const { refetch } = query

  React.useEffect(() => {
    if (!immat) return
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [immat])

  return prioritizePhotos(query.data)
}

export { prioritizePhotos }
export { useGetPhotos }
