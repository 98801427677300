const vpaExtranetPrefix = 'VPA_Extranet_'

const LOCAL_STORAGE_KEYS = {
  AFTER_SIGN_IN_URL: `${vpaExtranetPrefix}afterSignInUrl`,
  IS_LOGGING: `${vpaExtranetPrefix}isLogging`,
} as const

type LocalStorageKeys = keyof typeof LOCAL_STORAGE_KEYS
type LocalStorageValues = (typeof LOCAL_STORAGE_KEYS)[LocalStorageKeys]

const COOKIE_KEYS = {
  REMEMBER_ME: `${vpaExtranetPrefix}rememberMe`,
  LOGIN_TYPE: `${vpaExtranetPrefix}loginType`,
} as const

type CookieKeys = keyof typeof COOKIE_KEYS
type CookieValues = (typeof COOKIE_KEYS)[CookieKeys]

export type { LocalStorageKeys, LocalStorageValues, CookieKeys, CookieValues }
export { vpaExtranetPrefix, LOCAL_STORAGE_KEYS, COOKIE_KEYS }
