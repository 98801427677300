import React from 'react'
import { ThemeProvider } from '@mui/material'
import { theme } from 'utils/theme'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { AlertProvider } from './AlertContext'
import { FutureSaleProvider } from './FutureSaleContext'
import { SaleResultProvider } from './SaleResultContext'
import { SellerProvider } from './SellerContext'
import { WaitingFilesCountProvider } from './WaitingFilesCountContext'
import { ReserveQueueContextProvider } from './ReserveQueueContext'

const queryClient = new QueryClient()

const AppProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      <AlertProvider>
        <SellerProvider>
          <WaitingFilesCountProvider>
            <FutureSaleProvider>
              <SaleResultProvider>
                <ReserveQueueContextProvider>{children}</ReserveQueueContextProvider>
              </SaleResultProvider>
            </FutureSaleProvider>
          </WaitingFilesCountProvider>
        </SellerProvider>
      </AlertProvider>
    </ThemeProvider>
  </QueryClientProvider>
)

export default AppProvider
