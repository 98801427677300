import React from 'react'

type ReserveTaskStatus = 'new' | 'pending' | 'error'
type ReserveQueueTask = {
  project: string
  newReserve: number
  oldReserve: number
  status: ReserveTaskStatus
}

const defaultReserveQueueTask: ReserveQueueTask = {
  project: '',
  newReserve: 0,
  oldReserve: 0,
  status: 'new',
}

type ReserveSuccessfulTask = {
  project: ReserveQueueTask['project']
  reserve: ReserveQueueTask['newReserve']
}

type ReserveQueueContextProps = {
  queue: Array<ReserveQueueTask>
  addTask: (
    _project: ReserveQueueTask['project'],
    _newReserve: ReserveQueueTask['newReserve'],
    _oldReserve: ReserveQueueTask['oldReserve'],
  ) => void
  addTasks: (_arrayOfNewTasks: ReserveQueueTask[]) => void
  deleteTask: (_project: ReserveQueueTask['project']) => void
  onTaskSuccess: (_project: ReserveQueueTask['project']) => void
  changeTaskStatus: (
    _project: ReserveQueueTask['project'],
    _status: ReserveQueueTask['status'],
  ) => void
  getTask: (_project: ReserveQueueTask['project']) => ReserveQueueTask | null
  getLastSuccessfulTask: (
    _project: ReserveSuccessfulTask['project'],
  ) => ReserveSuccessfulTask | null
  hasTask: boolean
  successfulTasks: Array<ReserveSuccessfulTask>
  numberOfSuccess: number
}

const initialReserveQueueContext: ReserveQueueContextProps = {
  queue: [],
  addTask: (_project, _newReserve, _oldReserve) => {},
  addTasks: (_arrayOfNewTasks) => {},
  deleteTask: (_project) => {},
  onTaskSuccess: (_project) => {},
  changeTaskStatus: (_project, _status) => {},
  getTask: (_project) => null,
  getLastSuccessfulTask: (_project) => null,
  hasTask: false,
  successfulTasks: [],
  numberOfSuccess: 0,
}

const ReserveQueueContext = React.createContext<ReserveQueueContextProps>(
  initialReserveQueueContext,
)

const ReserveQueueContextProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [queue, setQueue] = React.useState<ReserveQueueTask[]>([])
  const [successfulTasks, setSuccessfulTasks] = React.useState<ReserveSuccessfulTask[]>([])

  const getTask = (project: ReserveQueueTask['project']) => {
    return queue.find((task) => task.project === project) || null
  }

  const getLastSuccessfulTask = (project: ReserveQueueTask['project']) => {
    return successfulTasks.find((task) => task.project === project) || null
  }

  const addTask = (project: string, newReserve: number, oldReserve: number) => {
    const newTask: ReserveQueueTask = {
      project,
      newReserve,
      oldReserve,
      status: 'new',
    }
    // To remove possibly existing task with same project and status 'error'
    const filteredQueue = queue.filter((task) => task.project !== project)

    setQueue([...filteredQueue, newTask])
  }
  const addTasks = (arrayOfNewTasks: ReserveQueueTask[]) => {
    const newQueue = [...queue, ...arrayOfNewTasks]
    setQueue(newQueue)
  }

  const deleteTask = (project: ReserveQueueTask['project']) => {
    const newQueue = queue.filter((task) => task.project !== project)
    setQueue(newQueue)
  }

  const onTaskSuccess = (project: ReserveQueueTask['project']) => {
    const currentTask = getTask(project) as ReserveQueueTask
    const successfulTask: ReserveSuccessfulTask = {
      project: currentTask.project,
      reserve: currentTask.newReserve,
    }
    setSuccessfulTasks([successfulTask, ...successfulTasks])
    deleteTask(project)
  }

  const changeTaskStatus = (
    project: ReserveQueueTask['project'],
    status: ReserveQueueTask['status'],
  ) => {
    const updatedQueue = queue.map((task) => {
      if (task.project !== project) return task
      return {
        ...task,
        status,
      } as ReserveQueueTask
    })
    setQueue(updatedQueue)
  }

  const contextValue = React.useMemo(
    () => ({
      queue,
      addTask,
      addTasks,
      deleteTask,
      onTaskSuccess,
      changeTaskStatus,
      getTask,
      getLastSuccessfulTask,
      hasTask: queue.length > 0,
      successfulTasks,
      numberOfSuccess: successfulTasks.length,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queue, successfulTasks],
  )

  return (
    <ReserveQueueContext.Provider value={contextValue}>{children}</ReserveQueueContext.Provider>
  )
}

const useReserveQueue = () => React.useContext(ReserveQueueContext)

export { useReserveQueue, ReserveQueueContextProvider, defaultReserveQueueTask }
export type { ReserveQueueTask }
