import axios, { AxiosInstance } from 'axios'

const msalAxiosInstance: AxiosInstance = axios.create({
  baseURL: `${window.location.origin}/api/`,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
})

export default msalAxiosInstance
