import { FocusTrap } from '@mui/base'
import { Backdrop, CircularProgress, CircularProgressProps } from '@mui/material'

import React from 'react'

const useLoading = () => {
  const [isLoading, setIsLoading] = React.useState(false)

  const Loading = (props: CircularProgressProps) => (
    <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center">
      <CircularProgress
        {...props}
        className="relative bottom-32"
      />
    </div>
  )

  const LoadingBackDrop = ({ isOpen, text }: { isOpen: boolean; text?: string }) => (
    <FocusTrap open>
      <Backdrop
        sx={{
          zIndex: 9999,
          cursor: 'default',
          backgroundColor: '#0005',
        }}
        open={isOpen}
      >
        <div className="flex flex-col items-center justify-between">
          {text ? <p className="mb-4 text-xl text-white">{text}</p> : null}
          <CircularProgress />
        </div>
      </Backdrop>
    </FocusTrap>
  )

  return {
    isLoading,
    setIsLoading,
    Loading,
    LoadingBackDrop,
  }
}

export default useLoading
