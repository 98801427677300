/* eslint-disable max-len */

const createUnmatchedErrorMessage = (unmatchedImmats: Array<string>): string => {
  const unmatchedCount = unmatchedImmats.length

  if (unmatchedCount === 0) return ''

  const isPlural = unmatchedCount > 1
  const vehicleMessagePart = isPlural ? 'les véhicules' : 'le véhicule'
  const unmatchedErrorMessage = `La vente ne contient aucune ligne pour ${vehicleMessagePart}: ${unmatchedImmats.join(
    ', ',
  )}`

  return unmatchedErrorMessage
}

const createDuplicateErrorMessage = (duplicatedImmats: Array<string>): string => {
  const duplicationCount = duplicatedImmats.length

  if (duplicationCount === 0) return ''

  const isPlural = duplicationCount > 1
  const vehicleMessagePart = isPlural ? 'les véhicules' : 'le véhicule'
  const duplicateErrorMessage = `La vente contient plusieurs lignes pour ${vehicleMessagePart}: ${duplicatedImmats.join(
    ', ',
  )}`

  return duplicateErrorMessage
}

export { createUnmatchedErrorMessage, createDuplicateErrorMessage }
