import React from 'react'

type NotificationBadgeProps = {
  number: number
}

const NotificationBadge = ({ number }: NotificationBadgeProps) => {
  if (number === 0) {
    return null
  }
  const displayedNumber = number < 99 ? number.toString() : '99+'
  return (
    <span
      className={`relative right-0 items-center ${
        displayedNumber.length === 1 ? 'px-2' : 'px-1'
      } py-1 text-xs leading-none text-red-100 bg-red-500 rounded-full bottom-3 `}
    >
      {displayedNumber}
    </span>
  )
}

export default NotificationBadge
