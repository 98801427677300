import React from 'react'
import { ComboBox } from 'components/common'
import { useSeller, useWaitingFilesCount } from 'contexts'
import { SellerDTO } from 'models'
import { useGetOneSellerFromEachFamily } from 'utils/hooks/queries'
import { AutocompleteChangeReason } from '@mui/material'
import { theme } from 'utils/theme'

const NavBarSellerFamily = () => {
  const {
    currentSellerFamily,
    setSelectedSellerFamily,
    setAllSellerFamilies,
    removeSelectedSellerFamily,
    removeSelectedSeller,
  } = useSeller()
  const { data: allFamiliesData } = useGetOneSellerFromEachFamily()

  const { setWaitingFilesCount } = useWaitingFilesCount()

  const allFamilies = allFamiliesData || []
  const selectedSellerFamily = allFamilies.find(
    (seller) => seller.cardNameFamille === currentSellerFamily?.cardNameFamille,
  )

  const handleSellerFamilySelection = (
    event: React.SyntheticEvent<Element, Event>,
    option: SellerDTO | null | undefined,
    reason: AutocompleteChangeReason,
  ) => {
    removeSelectedSeller()
    setWaitingFilesCount(0)
    if (reason === 'clear') {
      removeSelectedSellerFamily()
      return
    }
    setSelectedSellerFamily(option as SellerDTO)
  }

  React.useEffect(() => {
    setAllSellerFamilies(allFamilies)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFamilies])

  return allFamilies.length > 0 ? (
    <ComboBox
      options={allFamilies}
      getOptionLabel={(option) => option?.cardNameFamille || ''}
      selectedOption={selectedSellerFamily}
      onSelection={handleSellerFamilySelection}
      boxStyle={
        selectedSellerFamily
          ? undefined
          : {
              border: `3px solid ${theme.palette.primary.main}`,
            }
      }
    />
  ) : null
}

export default NavBarSellerFamily
