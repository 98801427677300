import { FutureSaleDTO, SaleResultDTO } from 'models'
// TODO: repasser à un traitemet différnet pas page

const generateSalesTableTitle = (sale: FutureSaleDTO | SaleResultDTO, hideLib?: boolean) => {
  const isSaleOnline = sale.typeVente === 'Electronique'
  if (isSaleOnline) {
    return `Vente en ligne du ${sale.dateVenteFR}:
  ${sale.lib}`
  }

  if (hideLib) {
    return `Vente du ${sale.dateVenteFR} à ${sale.lib_Vente}`
  }
  return `Vente du ${sale.dateVenteFR} à ${sale.lib_Vente}:
  ${sale.lib}`
}

export { generateSalesTableTitle }
