import { useQuery } from '@tanstack/react-query'
import { SellerDTO } from 'models'
import { currentQueryService } from 'utils/api/queries'

type GetSellerByFamilyParams = {
  sellerFamilyCode: string
}

const useGetSellersByFamily = (params: GetSellerByFamilyParams) => {
  return useQuery<Array<SellerDTO>, Error>({
    queryKey: ['getSellersByFamily', params.sellerFamilyCode],
    queryFn: () => currentQueryService.getSellersByFamily(params.sellerFamilyCode),
    enabled: false,
  })
}

export { useGetSellersByFamily }
