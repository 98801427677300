const LOGIN_TYPE = {
  B2C: 'B2C',
  B2B: 'B2B',
} as const

type LoginTypeKeys = keyof typeof LOGIN_TYPE
type LoginType = (typeof LOGIN_TYPE)[LoginTypeKeys]

export { LOGIN_TYPE }
export type { LoginType }
