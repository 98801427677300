import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { FutureSaleDTO } from 'models'
import { currentQueryService } from 'utils/api/queries'
import { useSeller } from 'contexts'

const useGetFutureSales = () => {
  const { currentSeller, isFamilyAllSellersOptionSelected } = useSeller()

  const query = useQuery<Array<FutureSaleDTO>, Error>({
    queryKey: ['getFutureSales', currentSeller.cardName],
    queryFn: () => currentQueryService.getFutureSales(currentSeller),
    enabled: false,
  })

  const { refetch } = query

  React.useEffect(() => {
    if (!isFamilyAllSellersOptionSelected && !currentSeller?.cardCode) return
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSeller])

  return query
}

export { useGetFutureSales }
