import { ImportedReserveInstruction } from 'models'
import { allowedTerms } from './checkHeaders'
import { normaliseImmat } from '../format'

const standardizeHeadersName = (headers: Array<string>) => {
  const standardizedHeaders = headers.map((header) => {
    const immatIndex = allowedTerms.immatriculation.indexOf(header)
    if (immatIndex !== -1) {
      return 'importImmat'
    }

    const reserveIndex = allowedTerms.reserve.indexOf(header)
    if (reserveIndex !== -1) {
      return 'importReserve'
    }
    return header
  })

  return standardizedHeaders
}

const standardizeParsedCSV = (parsedCSV: string[][]) => {
  const csvHeaders = parsedCSV[0]
  const standardizedHeaders = standardizeHeadersName(csvHeaders)

  // Turn array of string into array of objects with only 'immat' and 'reserve' as keys
  const immatIndex = standardizedHeaders.indexOf('importImmat')
  const reserveIndex = standardizedHeaders.indexOf('importReserve')

  /// Chop off array's header line
  const parsedCSVWithoutHeader = parsedCSV.filter((_line, index) => index > 0)

  const standardizedParsedCSV = parsedCSVWithoutHeader.map((line) => {
    const importReserveWithoutSpaces = line[reserveIndex].replace(/\s/g, '').replace(/,/g, '.')
    const isAnInteger = parseFloat(importReserveWithoutSpaces) % 1 === 0
    const instruction: ImportedReserveInstruction = {
      importImmat: normaliseImmat(line[immatIndex]),
      importReserve: isAnInteger ? parseInt(importReserveWithoutSpaces, 10) : NaN,
    }
    return instruction
  })

  return standardizedParsedCSV
}

export { standardizeParsedCSV }
