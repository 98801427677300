import React from 'react'
import { DataTable } from 'components/dataTable'
import { salesResultPageLabels } from 'utils/labels'
import { useNavigate } from 'react-router-dom'
import { useGetSalesResult } from 'utils/hooks/queries'
import { useAlert, useSaleResult } from 'contexts'
import { useCheckData, useLoading } from 'utils/hooks'
import { SaleResultDTO, defaultSaleResultDTO } from 'models'

const SalesResultPage = () => {
  const navigate = useNavigate()
  const { data: salesResultData, isLoading, error: getError } = useGetSalesResult()

  const { displayAlert } = useAlert()
  const { Loading } = useLoading()
  const { setSaleResult } = useSaleResult()
  const { dataAfterCheck, isDataEmpty } = useCheckData<SaleResultDTO>(
    salesResultData,
    defaultSaleResultDTO,
  )

  const salesResult: Array<SaleResultDTO> = dataAfterCheck

  React.useEffect(() => {
    if (getError?.message) {
      displayAlert('Erreur lors du chargement des ventes futures', 'error')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getError])

  if (isLoading) return <Loading />
  if (isDataEmpty) return <p>Aucune vente à afficher.</p>

  return (
    <DataTable
      columnsTitles={salesResultPageLabels}
      tableTitle="Résultats de vente"
      data={salesResult}
      options={{
        onRowClick: (sale) => {
          const typedSale = sale as unknown as SaleResultDTO
          if (typedSale && typedSale.docEntryVente) {
            setSaleResult(typedSale)
            navigate(`${typedSale.docEntryVente.toString()}`)
          }
        },
      }}
    />
  )
}

export default SalesResultPage
