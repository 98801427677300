import { SellerDTO, familyAllSellersCardName } from 'models'

class OdataFilterBuilder {
  private query: string = '$filter='

  add(condition: string): OdataFilterBuilder {
    this.query += condition
    return this
  }

  and(condition: string): OdataFilterBuilder {
    this.query += ` and ${condition}`
    return this
  }

  or(condition: string): OdataFilterBuilder {
    this.query += ` or ${condition}`
    return this
  }

  addCode(seller: SellerDTO): OdataFilterBuilder {
    const filterToAdd =
      seller.cardName === familyAllSellersCardName
        ? `CardCodeFamille eq '${seller.cardCodeFamille}'`
        : `CardCode eq '${seller.cardCode}'`

    this.query += filterToAdd
    return this
  }

  build(): string {
    return this.query
  }
}

export { OdataFilterBuilder }
