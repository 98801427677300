import React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useMsal } from '@azure/msal-react'
import { useNavigate } from 'react-router-dom'
import { msalAxiosInstance } from 'api'
import { getToken } from 'utils/api'
import { PATHS } from 'utils/constants'
import { SellerProvider } from './SellerContext'
import { WaitingFilesCountProvider } from './WaitingFilesCountContext'
import { FutureSaleProvider } from './FutureSaleContext'
import { SaleResultProvider } from './SaleResultContext'
import { useMsalSetup } from './MsalSetupContext'

const queryClient = new QueryClient()

const PrivateRouteProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { instance } = useMsal()
  const { msalSetup } = useMsalSetup()

  const navigate = useNavigate()

  const interceptMsalAxiosRequest = () => {
    msalAxiosInstance.interceptors.request.use(
      async (config) => {
        const token = await getToken(instance, msalSetup.loginRequest)

        if (token) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `Bearer ${token}`
        } else {
          instance.logoutRedirect()
          navigate(PATHS.signIn)
        }

        return config
      },
      (error) => {
        return Promise.reject(error)
      },
    )
  }

  interceptMsalAxiosRequest()

  return (
    <QueryClientProvider client={queryClient}>
      <SellerProvider>
        <WaitingFilesCountProvider>
          <FutureSaleProvider>
            <SaleResultProvider>{children}</SaleResultProvider>
          </FutureSaleProvider>
        </WaitingFilesCountProvider>
      </SellerProvider>
    </QueryClientProvider>
  )
}

export default PrivateRouteProvider
