import React from 'react'
import VPautoLogoStringified from 'assets/logo_VPauto_195px.png'

const VPautoLogo = (
  props: React.JSX.IntrinsicAttributes &
    React.ClassAttributes<HTMLImageElement> &
    React.ImgHTMLAttributes<HTMLImageElement>,
) => (
  <img
    src={VPautoLogoStringified}
    alt="company logo"
    {...props}
  />
)

export { VPautoLogo }
