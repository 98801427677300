/* eslint-disable camelcase */
import { DateISO8601 } from './isoDate'

type VehicleSheetDTO = {
  metadata: {
    type: string
    uri: string
  }
  project: string
  dossierRefVendeur: string
  dateSortie: DateISO8601 | null
  dateMission: DateISO8601 | null
  dateCreation: DateISO8601 | null
  dateLivraison: DateISO8601 | null
  date1MEC: DateISO8601 | null
  dateVenteAffecteeE: DateISO8601 | null
  dateVenteAffectee: DateISO8601 | null
  prixAdjudication: number | null
  coteStandard: number | null
  cotePerso: number | null
  coteKm: number | null
  nbRetrait: number | null
  nbRetraitE: number | null
  estimation: number | null
  reserve: number | null
  kilomReel: number | null
  kilomDeclare: number | null
  observationsVendeur: string | null
  immatriculation: string
  statut: string | null
  sousStatut: string | null
  numeroSerie: string
  type: string
  carrosserie: string
  segment: string
  prixRetire: number | null
  magasinSAP: string
  societeDeVente: string
  genre: string
  marque: string
  typeComm: string
  codeParcStockage: number | null
  immatriculation2: string
  fre: number | null
  proprietaireDebiteur: string
  certificatImmatriculation: string
  u_W3C_SFEI: string
  cardCode: string
  cardName: string
  docEntryVente: number | null
  docEntryVenteE: number | null
  numeroOrdre: string
  numeroOrdreComplement: string
  tva: string
  avarie: string
  couleur: string
  energie: string
  puissance: number | null
  etat: string
  places: number | null
  autoEcole: string
  avaries: string
  suiviEntretien: string
  carteCode: string
  clefs: string
  courroie: string
  suiviCertifImmat: string
  noteVisuelle: string
  interieur: string
  prixNeuf: number | null
  expertise: string
  age: number | null
  roueSecoursAbsente: string
  finGarantieContructeur: DateISO8601 | null
  tarifSpecial: string
  tarifSpecialElectronique: string
  urlBE: string | null
  urlSE: string | null
  urlCG: string | null
  urlCT: string | null
  urlET: string | null
  url3D: string | null
  nbJoursStock: number | null
  heure: number | null
  heureE: number | null
  equipementsPrint: string
  modeleArgus: string
  scanCT: string
  scanCG: string
  batterie: string
  denonce: string
  dateDerniereCotation: string | null
  memoExterne: string
}

type VehicleSheetFormatted = Omit<
  VehicleSheetDTO,
  | 'dateSortie'
  | 'dateMission'
  | 'dateCreation'
  | 'dateLivraison'
  | 'date1MEC'
  | 'dateVenteAffectee'
  | 'dateVenteAffecteeE'
> & {
  dateSortie: string
  dateMission: string
  dateCreation: string
  dateLivraison: string
  date1MEC: string
  dateVenteAffectee: string
  dateVenteAffecteeE: string
}

type VehicleSheetOverLoad = VehicleSheetFormatted & {
  numeroDeDecompte: string
  carnet: boolean
  reserve1: string // calcul
  pcentCoteDatee: string // calcul
  pcentCoteKm: string // calcul
  opposition: string
  procedure: string
  tribunal: string
  mandataire: string
  jugement: string
  juge: string
  commentaire: string
  denonce: string
  coDebiteur: string
  caution: string
  caution1: string
  finGC: string
  res1: string
}

const defaultVehicleDTO: VehicleSheetDTO = {
  project: '',
  dossierRefVendeur: '',
  dateSortie: null,
  dateMission: null,
  dateCreation: null,
  dateLivraison: null,
  date1MEC: null,
  prixAdjudication: null,
  coteStandard: null,
  cotePerso: null,
  coteKm: null,
  nbRetrait: null,
  nbRetraitE: null,
  estimation: null,
  reserve: null,
  kilomReel: null,
  kilomDeclare: null,
  observationsVendeur: '',
  immatriculation: '',
  statut: '',
  sousStatut: '',
  numeroSerie: '',
  type: '',
  carrosserie: '',
  segment: '',
  prixRetire: null,
  magasinSAP: '',
  societeDeVente: '',
  genre: '',
  marque: '',
  typeComm: '',
  codeParcStockage: null,
  fre: null,
  proprietaireDebiteur: '',
  certificatImmatriculation: '',
  u_W3C_SFEI: '',
  cardCode: '',
  cardName: '',
  docEntryVente: null,
  docEntryVenteE: null,
  numeroOrdre: '',
  numeroOrdreComplement: '',
  tva: '',
  avarie: '',
  couleur: '',
  energie: '',
  puissance: null,
  etat: '',
  places: null,
  autoEcole: '',
  avaries: '',
  suiviEntretien: '',
  carteCode: '',
  clefs: '',
  courroie: '',
  suiviCertifImmat: '',
  noteVisuelle: '',
  interieur: '',
  prixNeuf: null,
  expertise: '',
  age: null,
  metadata: {
    type: '',
    uri: '',
  },
  immatriculation2: '',
  finGarantieContructeur: null,
  roueSecoursAbsente: '',
  tarifSpecial: '',
  tarifSpecialElectronique: '',
  dateVenteAffecteeE: null,
  dateVenteAffectee: null,
  urlBE: '',
  urlSE: '',
  urlCG: '',
  urlCT: '',
  urlET: '',
  url3D: '',
  nbJoursStock: null,
  heure: null,
  heureE: null,
  equipementsPrint: '',
  modeleArgus: '',
  scanCG: '',
  scanCT: '',
  batterie: '',
  denonce: '',
  dateDerniereCotation: '',
  memoExterne: '',
}

const defaultVehicleOverload: VehicleSheetOverLoad = {
  dateSortie: '',
  dateMission: '',
  dateCreation: '',
  dateLivraison: '',
  date1MEC: '',
  dateDerniereCotation: '',
  project: '',
  dossierRefVendeur: '',
  prixAdjudication: null,
  coteStandard: null,
  cotePerso: null,
  coteKm: null,
  nbRetrait: null,
  nbRetraitE: null,
  estimation: null,
  reserve: null,
  kilomReel: null,
  kilomDeclare: null,
  observationsVendeur: '',
  immatriculation: '',
  statut: '',
  sousStatut: '',
  numeroSerie: '',
  type: '',
  carrosserie: '',
  segment: '',
  prixRetire: null,
  magasinSAP: '',
  societeDeVente: '',
  genre: '',
  marque: '',
  typeComm: '',
  codeParcStockage: null,
  fre: null,
  proprietaireDebiteur: '',
  certificatImmatriculation: '',
  u_W3C_SFEI: '',
  cardCode: '',
  cardName: '',
  docEntryVente: null,
  docEntryVenteE: null,
  numeroOrdre: '',
  numeroOrdreComplement: '',
  tva: '',
  avarie: '',
  couleur: '',
  energie: '',
  puissance: null,
  etat: '',
  places: null,
  autoEcole: '',
  avaries: '',
  suiviEntretien: '',
  carteCode: '',
  clefs: '',
  courroie: '',
  suiviCertifImmat: '',
  noteVisuelle: '',
  interieur: '',
  prixNeuf: null,
  expertise: '',
  age: null,
  numeroDeDecompte: '',
  heure: null,
  heureE: null,
  carnet: false,
  batterie: '',
  equipementsPrint: '',
  reserve1: '',
  pcentCoteDatee: '',
  pcentCoteKm: '',
  opposition: '',
  procedure: '',
  tribunal: '',
  mandataire: '',
  jugement: '',
  juge: '',
  commentaire: '',
  denonce: '',
  coDebiteur: '',
  caution: '',
  caution1: '',
  roueSecoursAbsente: '',
  finGC: '',
  res1: '',
  metadata: {
    type: '',
    uri: '',
  },
  immatriculation2: '',
  finGarantieContructeur: null,
  tarifSpecial: '',
  tarifSpecialElectronique: '',
  dateVenteAffecteeE: '',
  dateVenteAffectee: '',
  urlBE: '',
  urlSE: '',
  urlCG: '',
  urlCT: '',
  urlET: '',
  url3D: '',
  nbJoursStock: null,
  modeleArgus: '',
  scanCG: '',
  scanCT: '',
  memoExterne: '',
}

export type { VehicleSheetDTO, VehicleSheetOverLoad }
export { defaultVehicleDTO, defaultVehicleOverload }
