import { VehicleSheetDTO } from 'models'

type SearchByImmatPropertiesToDisplay = Pick<
  VehicleSheetDTO,
  | 'dossierRefVendeur'
  | 'immatriculation'
  | 'marque'
  | 'typeComm'
  | 'dateLivraison'
  | 'dateVenteAffectee'
  | 'dateVenteAffecteeE'
  | 'statut'
  | 'sousStatut'
  | 'estimation'
  | 'coteKm'
  | 'cotePerso'
>
type SearchByImmatLabels = Record<keyof SearchByImmatPropertiesToDisplay, string>

const searchByImmatLabels: SearchByImmatLabels = {
  dossierRefVendeur: 'Réf du vendeur',
  immatriculation: 'Immatriculation',
  marque: 'Marque',
  typeComm: 'Type com',
  dateLivraison: 'Entrée sur le parc',
  dateVenteAffectee: 'Date de vente',
  dateVenteAffecteeE: 'Date vente électro',
  statut: 'Statut général',
  sousStatut: 'Sous-Statut',
  estimation: 'Estimation',
  coteKm: 'Cote Km',
  cotePerso: 'Cote Datée',
}

export { searchByImmatLabels }
export type { SearchByImmatLabels }
