import React from 'react'
import { InteractionStatus } from '@azure/msal-browser'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { Navigate, useNavigate } from 'react-router-dom'
import { LOCAL_STORAGE_KEYS, PATHS } from 'utils/constants'

const AuthorizationCallbackPage: React.FC = () => {
  const { inProgress } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  const navigate = useNavigate()

  const localStorageAfterSignInPath: string =
    localStorage.getItem(LOCAL_STORAGE_KEYS.AFTER_SIGN_IN_URL) || PATHS.home

  const afterSignInPath =
    localStorageAfterSignInPath === PATHS.signIn ? PATHS.home : localStorageAfterSignInPath

  React.useEffect(() => {
    return () => localStorage.removeItem(LOCAL_STORAGE_KEYS.AFTER_SIGN_IN_URL)
  }, [])

  React.useEffect(() => {
    if (inProgress !== InteractionStatus.None || isAuthenticated) return

    const timer = setTimeout(() => {
      navigate(`/${PATHS.signIn}`)
    }, 3000) // 2 seconds

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inProgress, isAuthenticated])

  return (
    <div className="flex flex-col justify-center m-4">
      <div>
        <p className="text-center pt-36">
          {((): string => {
            if (inProgress !== InteractionStatus.None) return 'Authentification en cours...'
            if (isAuthenticated) return 'Authentification réussie. Redirection en cours...'
            return "Erreur lors de l'authentification. Redirection vers la page de connexion."
          })()}
        </p>
        {inProgress === InteractionStatus.None && isAuthenticated ? (
          <Navigate to={afterSignInPath} />
        ) : null}
      </div>
    </div>
  )
}

export default AuthorizationCallbackPage
