import Cookies from 'js-cookie'
import { COOKIE_KEYS, LOGIN_TYPE, LoginType } from 'utils/constants'

const setLoginTypeCookie = (loginType: LoginType) => {
  Cookies.set(COOKIE_KEYS.LOGIN_TYPE, loginType, { expires: 365 })
}

const getLoginTypeCookie = (): LoginType => {
  const loginType = Cookies.get(COOKIE_KEYS.LOGIN_TYPE)
  if (loginType) {
    return loginType as LoginType
  }
  Cookies.set(COOKIE_KEYS.LOGIN_TYPE, LOGIN_TYPE.B2C)
  return LOGIN_TYPE.B2C
}

const removeLoginTypeCookie = () => {
  Cookies.remove(COOKIE_KEYS.LOGIN_TYPE)
}

const loginTypeCookie = {
  get: getLoginTypeCookie,
  set: setLoginTypeCookie,
  remove: removeLoginTypeCookie,
}

export { loginTypeCookie }
