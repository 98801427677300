import { FutureSaleDTO } from 'models'

type FutureSalePropertiesToDisplay = Pick<FutureSaleDTO, 'dateVenteFR' | 'lib_Vente' | 'lib'>
type FutureSalesTableLabels = Record<keyof FutureSalePropertiesToDisplay, string>

const futureSaleTableLabels: FutureSalesTableLabels = {
  dateVenteFR: 'Date de vente',
  lib_Vente: 'Nom de la vente',
  lib: 'Nombre de véhicules',
}

export { futureSaleTableLabels }
