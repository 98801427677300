import { useQuery } from '@tanstack/react-query'
import { VehicleSheetDTO } from 'models'
import { currentQueryService } from 'utils/api/queries'

type GetVehicleParams = {
  vehicleId: string
}

const useGetVehicle = (params: GetVehicleParams) => {
  const query = useQuery<VehicleSheetDTO, Error>({
    queryKey: ['getVehicle', params.vehicleId],
    queryFn: () => currentQueryService.getVehicle(params.vehicleId),
    enabled: false,
  })

  return query
}

export { useGetVehicle }
