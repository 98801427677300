import { VehicleSheetOverLoad, defaultVehicleOverload } from 'models'
import React from 'react'

const VehicleContext = React.createContext<VehicleSheetOverLoad>(defaultVehicleOverload)

const useVehicle = () => {
  const vehicleContext = React.useContext(VehicleContext)
  return vehicleContext
}
export { VehicleContext, useVehicle }
