import { Configuration, SilentRequest } from '@azure/msal-browser'
import { msalCommonConfig } from './msalCommonConfig'

const msalConfigB2B: Configuration = {
  auth: {
    ...msalCommonConfig.auth,
    clientId: process.env.REACT_APP_B2B_CLIENT_ID ?? '',
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_B2B_TENANT}`,
  },
}

const loginRequestB2B: Pick<SilentRequest, 'scopes'> = {
  scopes: [`api://${process.env.REACT_APP_B2B_CLIENT_ID}/default`],
}
export { msalConfigB2B, loginRequestB2B }
