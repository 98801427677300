import { useIsAuthenticated } from '@azure/msal-react'
import { PrivateRouteProvider } from 'contexts'

import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { PATHS } from 'utils/constants'

const PrivateRoute = () => {
  const isAuthenticated = useIsAuthenticated()

  return isAuthenticated ? (
    <PrivateRouteProvider>
      <Outlet />
    </PrivateRouteProvider>
  ) : (
    <Navigate to={PATHS.signIn} />
  )
}

export default PrivateRoute
