import { SaleResultDTO, defaultSaleResultDTO } from 'models'
import React from 'react'

type SaleResultContextType = {
  saleResult: SaleResultDTO
  setSaleResult: React.Dispatch<React.SetStateAction<SaleResultDTO>>
}

const SaleResultContext = React.createContext<SaleResultContextType>({
  saleResult: defaultSaleResultDTO,
  setSaleResult: () => {},
})

const SaleResultProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [saleResult, setSaleResult] = React.useState<SaleResultDTO>(defaultSaleResultDTO)

  const providerValue = React.useMemo(
    () => ({
      saleResult,
      setSaleResult,
    }),
    [saleResult],
  )

  return <SaleResultContext.Provider value={providerValue}>{children}</SaleResultContext.Provider>
}

const useSaleResult = () => React.useContext(SaleResultContext)

export { SaleResultContext, SaleResultProvider, useSaleResult }
