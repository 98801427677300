/* eslint-disable camelcase */
type SellerDTO = {
  metadata: {
    type: string
    uri: string
  }
  cardCode: string
  cardName: string
  u_W3C_SFEI: string | null
  u_W3C_ORIG: string | null
  cardCodeFamille: string
  cardNameFamille: string
  u_W3C_SFEI_Famille: string | null
  inactif: string
}

const defaultSellerDTO: SellerDTO = {
  metadata: {
    type: '',
    uri: '',
  },
  cardCode: '',
  cardName: '',
  u_W3C_SFEI: null,
  u_W3C_ORIG: null,
  cardCodeFamille: '',
  cardNameFamille: '',
  u_W3C_SFEI_Famille: null,
  inactif: '',
}

// Used to display custom name and filter queries by familyCardCode
const familyAllSellersCardName = 'Tous les comptes'

export type { SellerDTO }
export { defaultSellerDTO, familyAllSellersCardName }
