import { DateISO8601 } from './isoDate'

/* eslint-disable camelcase */
type SaleResultDTO = {
  metadata: {
    type: string
    uri: string
  }
  dateVente: string
  dateVenteFR: string
  lib_Vente: string
  lib: string
  typeVente: string
  cardCode: string
  docEntryVente: number | null
}

type SaleResultOverload = SaleResultDTO & {
  u_W3C_SFEI: string
  u_W3C_XCLE: string
}

type SaleResultVehicleDTO = {
  metadata: {
    type: string
    uri: string
  }
  immatriculation: string
  genre: string
  typeComm: string
  date1MEC: DateISO8601 | null
  project: string
  reserve: number | null
  kilomReel: number | null
  coteKm: number | null
  cotePerso: number | null
  coteStandard: number | null
  observationsVendeur: string
  cardCode: string
  tva: string
  etatVente: string
  docEntryVente: number | null
  dateVente: string
  typeVente: string
  prixAdjudication: number | null
  prixRetire: number | null
  numeroOrdre: string
  numeroOrdreComplement: string
  couleur: string
  nbRetraitE: number | null
  nbRetrait: number | null
  estimation: number | null
}

type SaleResultVehicleFormatted = Omit<SaleResultVehicleDTO, 'date1MEC'> & {
  date1MEC: string
}

type SaleResultVehicleOverload = SaleResultVehicleFormatted & {
  percentCP: number | null
  percentCK: number | null
  nombrePassage: number | null
  dossierRefVendeur: string
}

const defaultSaleResultDTO: SaleResultDTO = {
  dateVenteFR: '',
  lib_Vente: '',
  lib: '',
  docEntryVente: null,
  dateVente: '',
  typeVente: '',
  cardCode: '',
  metadata: {
    type: '',
    uri: '',
  },
}

const defaultSaleResultOverload: SaleResultOverload = {
  ...defaultSaleResultDTO,
  u_W3C_SFEI: '',
  u_W3C_XCLE: '',
}

const defaultSaleResultVehicleDTO: SaleResultVehicleDTO = {
  metadata: {
    type: '',
    uri: '',
  },
  immatriculation: '',
  genre: '',
  typeComm: '',
  date1MEC: null,
  project: '',
  reserve: null,
  kilomReel: null,
  coteKm: null,
  cotePerso: null,
  coteStandard: null,
  observationsVendeur: '',
  cardCode: '',
  tva: '',
  etatVente: '',
  docEntryVente: null,
  dateVente: '',
  typeVente: '',
  prixAdjudication: null,
  numeroOrdre: '',
  numeroOrdreComplement: '',
  couleur: '',
  prixRetire: null,
  estimation: null,
  nbRetraitE: null,
  nbRetrait: null,
}

export { defaultSaleResultDTO, defaultSaleResultVehicleDTO, defaultSaleResultOverload }
export type { SaleResultDTO, SaleResultOverload, SaleResultVehicleDTO, SaleResultVehicleOverload }
