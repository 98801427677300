const useCheckData = <T>(data: Array<T> | undefined, defaultData: T) => {
  const hasData = data && data.length > 0
  const isDataEmpty = !hasData
  const dataAfterCheck = isDataEmpty ? [defaultData] : data

  return {
    hasData,
    isDataEmpty,
    dataAfterCheck,
  }
}

export default useCheckData
