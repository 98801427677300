/* eslint-disable camelcase */
type UserB2C = {
  emails: Array<string>
  family_name: string
  given_name: string
}

type UserB2B = {
  // unique_name is an email
  unique_name: string
  name: string
}

type AuthenticatedUser = {
  fullName: string
  email: string
}

const defaultAuthenticatedUser: AuthenticatedUser = {
  fullName: '',
  email: '',
}

export type { AuthenticatedUser, UserB2C, UserB2B }
export { defaultAuthenticatedUser }
