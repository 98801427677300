/* eslint-disable max-len */
import { ReserveImportError } from 'models'
import { capitalizeFirstLetter } from '../capitalizeFirstLetter'

// Warning : The first term is used to display error message
const allowedImmatTerms = ['immatriculation', 'immat', 'immat.']
const allowedReserveTerms = ['réserve', 'reserve', 'reserven']
const allowedTerms = {
  immatriculation: allowedImmatTerms,
  reserve: allowedReserveTerms,
}

const countOccurrences = (arrayToCheck: string[], referenceArray: string[]) => {
  let count = 0
  arrayToCheck.map((str) => {
    if (referenceArray.includes(str)) {
      count += 1
    }
    return null
  })

  return count
}

const createHeaderErrorMessage = (
  allowedHeaderTerms: Array<string>,
  headerOccurrences: number,
): string => {
  if (headerOccurrences === 1) return ''

  const headerName = capitalizeFirstLetter(allowedHeaderTerms[0])
  const headerAuthorizedNames = allowedHeaderTerms.join(', ')

  const containsMessagePart =
    headerOccurrences === 0 ? 'ne contient aucune colonne' : 'contient plusieurs colonnes'

  const errorMessage = `Le fichier ${containsMessagePart} "${headerName}" (Noms de colonnes autorisés: ${headerAuthorizedNames})`

  return errorMessage
}

const handleHeaderNameErrors = (
  csvHeaders: Array<string>,
  allowedHeaderTerms: Array<string>,
): ReserveImportError => {
  const headerOccurrences = countOccurrences(csvHeaders, allowedHeaderTerms)

  return {
    errorMessage: createHeaderErrorMessage(allowedHeaderTerms, headerOccurrences),
    hasError: headerOccurrences !== 1,
  }
}

// Check for errors in header
const checkHeadersForImmat = (csvHeaders: Array<string>): ReserveImportError => {
  return handleHeaderNameErrors(csvHeaders, allowedTerms.immatriculation)
}

const checkHeadersForReserve = (csvHeaders: Array<string>): ReserveImportError => {
  return handleHeaderNameErrors(csvHeaders, allowedTerms.reserve)
}

export {
  allowedTerms,
  countOccurrences,
  createHeaderErrorMessage,
  handleHeaderNameErrors,
  checkHeadersForImmat,
  checkHeadersForReserve,
}
