/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { VehicleSheetOverLoad } from 'models'
import { vehicleSheetLabels } from 'utils/labels'
import { useVehicle } from 'contexts'
import { Link } from 'react-router-dom'
import { CancelOutlined, CheckBox } from '@mui/icons-material'

type VehicleSheetScanWithoutStatusProps = {
  property: keyof Pick<VehicleSheetOverLoad, 'urlET' | 'urlBE'>
}

const VehicleSheetScanWithoutStatus = ({ property }: VehicleSheetScanWithoutStatusProps) => {
  const currentVehicle: VehicleSheetOverLoad = useVehicle()
  const link: string = currentVehicle[property] as string
  const isValid: boolean =
    (link !== null && link !== undefined && link?.startsWith('http')) ?? false
  const displayedColor: string = isValid ? 'green' : 'red'

  const icon = isValid ? (
    <CheckBox
      fontSize="large"
      className="mr-2 "
    />
  ) : (
    <CancelOutlined
      fontSize="large"
      className="mr-2 "
    />
  )

  if (isValid) {
    return (
      <Link
        to={link}
        target="blank"
        rel="noopener noreferrer"
        title="Voir le fichier"
        className="grid w-full grid-cols-12 my-2 "
        style={{
          color: displayedColor,
        }}
      >
        <span className="flex items-center col-span-7 font-bold ">
          {vehicleSheetLabels[property]}
        </span>
        <div className="flex items-center col-span-5 ml-1">
          {icon}
          <p>Voir</p>
        </div>
      </Link>
    )
  }
  return (
    <div
      className="grid w-full grid-cols-12 my-2 "
      style={{
        color: displayedColor,
        cursor: 'default',
      }}
    >
      <span className="flex items-center col-span-7 font-bold ">
        {vehicleSheetLabels[property]}
      </span>
      <div className="flex items-center col-span-5 ml-1">
        {icon}
        <p>Absent</p>
      </div>
    </div>
  )
}

export default VehicleSheetScanWithoutStatus
