import { StockVehicleDTO, defaultStockVehicle } from './stock'

type WaitingFileVehicleDTO = StockVehicleDTO

type WaitingFileVehicleFormatted = Omit<WaitingFileVehicleDTO, 'dateLivraison' | 'dateCreation'> & {
  dateLivraison: string
  dateCreation: string
}

const defaultWaitingFile: WaitingFileVehicleDTO = { ...defaultStockVehicle }

export { defaultWaitingFile }
export type { WaitingFileVehicleDTO, WaitingFileVehicleFormatted }
