import React from 'react'
import { FutureSaleDTO, defaultFutureSaleDTO } from 'models'
import { useCheckData, useLoading } from 'utils/hooks'
import { useGetFutureSales } from 'utils/hooks/queries'
import { useAlert, useFutureSale } from 'contexts'
import { DataTable } from 'components/dataTable'
import { futureSaleTableLabels } from 'utils/labels'
import { useNavigate } from 'react-router-dom'

const FutureSalesTable = ({
  title,
  salesData,
}: {
  title: string
  salesData: Array<FutureSaleDTO>
}) => {
  const navigate = useNavigate()
  const { setFutureSale } = useFutureSale()

  return (
    <DataTable
      tableTitle={title}
      columnsTitles={futureSaleTableLabels}
      data={salesData}
      options={{
        onRowClick: (sale) => {
          const typedSale = sale as unknown as FutureSaleDTO
          if (typedSale && typedSale.docEntryVente) {
            setFutureSale(typedSale)
            navigate(`${typedSale.docEntryVente.toString()}`)
          }
        },
      }}
    />
  )
}

const FutureSalesPage = () => {
  const { data: futureSalesData, isLoading, error: getError } = useGetFutureSales()
  const { displayAlert } = useAlert()
  const { Loading } = useLoading()
  const { dataAfterCheck, isDataEmpty } = useCheckData<FutureSaleDTO>(
    futureSalesData,
    defaultFutureSaleDTO,
  )

  const futureSales: Array<FutureSaleDTO> = dataAfterCheck

  React.useEffect(() => {
    if (getError?.message) {
      displayAlert('Erreur lors du chargement des ventes futures', 'error')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getError])

  const physicalSales = futureSales?.filter((sale) => {
    return sale.typeVente === 'Classique'
  })

  const onlineSales = futureSales?.filter((sale) => {
    return sale.typeVente === 'Electronique'
  })

  if (isLoading) return <Loading />
  if (isDataEmpty) return <p>Aucune vente à afficher.</p>

  return (
    <>
      {physicalSales.length > 0 && (
        <FutureSalesTable
          title="Ventes en salle"
          salesData={physicalSales}
        />
      )}
      {onlineSales.length > 0 && (
        <FutureSalesTable
          title="Ventes en ligne"
          salesData={onlineSales}
        />
      )}
    </>
  )
}

export default FutureSalesPage
