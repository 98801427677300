/* eslint-disable max-len */
import { ImmatriculationDuplicate, ImportedReserveInstruction, ReserveImportError } from 'models'

const createDuplicateErrorMessage = (duplicates: Array<ImmatriculationDuplicate>): string => {
  const duplicationCount = duplicates.length
  const allDuplicatedImmats = duplicates.map((duplicate) => duplicate.immatriculation).join(', ')

  if (duplicationCount === 0) return ''

  const isPlural = duplicationCount > 1
  const vehicleMessagePart = isPlural ? 'les véhicules' : 'le véhicule'
  const duplicateErrorMessage = `Le fichier contient plusieurs lignes de réserves pour ${vehicleMessagePart}: ${allDuplicatedImmats}`

  return duplicateErrorMessage
}

const checkImmatDuplicates = (
  instructions: Array<ImportedReserveInstruction>,
): ReserveImportError => {
  const allImmats = instructions.map((instruction) => instruction.importImmat)
  const singleOccurrenceImmats: Array<string> = []

  const duplicates = allImmats.reduce<Array<ImmatriculationDuplicate>>(
    (finalDuplicates, currentImmat) => {
      if (!singleOccurrenceImmats.includes(currentImmat)) {
        singleOccurrenceImmats.push(currentImmat)
        return finalDuplicates
      }

      const isImmatAlreadyDuplicated = finalDuplicates.some(
        (duplicatedInstruction) => duplicatedInstruction.immatriculation === currentImmat,
      )

      if (isImmatAlreadyDuplicated) {
        const updatedDuplicates = finalDuplicates.map((duplicate) => {
          if (duplicate.immatriculation === currentImmat) {
            return { ...duplicate, count: duplicate.count + 1 } as ImmatriculationDuplicate
          }
          return duplicate
        })
        return updatedDuplicates as Array<ImmatriculationDuplicate>
      }

      const newDuplicate: ImmatriculationDuplicate = {
        immatriculation: currentImmat,
        count: 1,
      }
      return [...finalDuplicates, newDuplicate]
    },
    [],
  )

  return {
    errorMessage: createDuplicateErrorMessage(duplicates),
    hasError: duplicates.length > 0,
  }
}

export { checkImmatDuplicates }
