import React from 'react'
import UploadIcon from '@mui/icons-material/Upload'
import useDataTableUploadButton from './useDataTableUploadButton'

const DataTableUploadButton = () => {
  const { hasTask, handleChange } = useDataTableUploadButton()

  return (
    <form className="flex items-center justify-center w-64 h-full">
      <input
        className="hidden"
        disabled={hasTask}
        type="file"
        accept=".csv, .xls, .xlsx"
        id="upload"
        onChange={(e) => handleChange(e)}
      />
      <label
        htmlFor="upload"
        className={hasTask ? 'text-gray-400' : 'cursor-pointer'}
      >
        <UploadIcon /> Import des réserves en masse
        {}
      </label>
    </form>
  )
}

export default DataTableUploadButton
