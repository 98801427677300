import {
  CancelOutlined,
  CheckBox,
  HourglassTopOutlined,
  PsychologyAltOutlined,
} from '@mui/icons-material'
import { useVehicle } from 'contexts'
import { VehicleSheetOverLoad } from 'models'
import React from 'react'
import { Link } from 'react-router-dom'
import { vehicleSheetLabels } from 'utils/labels'
import { theme } from 'utils/theme'

const scan = {
  absent: {
    backendValue: '0 : pas de scan',
    text: 'Absent',
    color: 'red',
    icon: (
      <CancelOutlined
        fontSize="large"
        className="mr-2 "
      />
    ),
  },
  toDo: {
    backendValue: '1 : scan à faire',
    text: 'À faire',
    color: theme.palette.primary.main,
    icon: (
      <HourglassTopOutlined
        fontSize="large"
        className="mr-2 "
      />
    ),
  },
  done: {
    backendValue: '2 : scan fait',
    text: 'Fait',
    color: 'green',
    icon: (
      <CheckBox
        fontSize="large"
        className="mr-2 "
      />
    ),
  },
  default: {
    backendValue: '',
    text: 'Erreur',
    color: 'gray',
    icon: (
      <PsychologyAltOutlined
        fontSize="large"
        className="mr-2 "
      />
    ),
  },
} as const

type VehicleSheetScanWithStatusProps = {
  scanType: keyof Pick<VehicleSheetOverLoad, 'scanCG' | 'scanCT' | 'suiviEntretien' | 'urlBE'>
}
const links: Record<
  keyof Pick<VehicleSheetOverLoad, 'scanCT' | 'scanCG' | 'suiviEntretien' | 'urlBE'>,
  keyof Partial<VehicleSheetOverLoad>
> = {
  scanCT: 'urlCT',
  scanCG: 'urlCG',
  suiviEntretien: 'urlSE',
  urlBE: 'urlBE',
}

const VehicleSheetScanWithStatus = ({ scanType }: VehicleSheetScanWithStatusProps) => {
  const currentVehicle: VehicleSheetOverLoad = useVehicle()
  const link = currentVehicle[links[scanType]] as string
  const currentStatus =
    Object.values(scan).find((scanState) => currentVehicle[scanType] === scanState.backendValue) ||
    scan.default
  const isScanDone = currentStatus.backendValue === scan.done.backendValue

  const content = (
    <>
      <span className="flex items-center col-span-7 font-bold">{vehicleSheetLabels[scanType]}</span>
      <div className="flex items-center col-span-5 ml-1">
        {currentStatus?.icon}
        <p>{isScanDone && link ? 'Voir' : currentStatus?.text}</p>
      </div>
    </>
  )

  return isScanDone && link !== null && link.startsWith('http') ? (
    <Link
      to={link}
      target="blank"
      rel="noopener noreferrer"
      title="Voir le fichier"
      className="grid w-full grid-cols-12 my-2 "
      style={{
        color: currentStatus?.color,
      }}
    >
      {content}
    </Link>
  ) : (
    <div
      {...(isScanDone ? { title: 'Pas de lien disponible' } : {})}
      className="grid w-full grid-cols-12 my-2 "
      style={{
        color: currentStatus?.color,
        cursor: 'default',
      }}
    >
      {content}
    </div>
  )
}

export default VehicleSheetScanWithStatus
