/* eslint-disable no-case-declarations */
import React from 'react'
import { SellerDTO, defaultSellerDTO, familyAllSellersCardName } from 'models'
import { isSellerNotDefault } from 'utils/helpers'

type SellerReducerState = {
  userSeller: SellerDTO
  selectedSeller: SellerDTO
  selectedSellerFamily: SellerDTO
  allSellerFamilies: Array<SellerDTO>
}

const defaultSellerReducerState: SellerReducerState = {
  userSeller: defaultSellerDTO,
  selectedSeller: defaultSellerDTO,
  selectedSellerFamily: defaultSellerDTO,
  allSellerFamilies: [],
}

const sellerActions = {
  SET_USER_SELLER: 'SET_USER_SELLER',
  SET_SELECTED_SELLER: 'SET_SELECTED_SELLER',
  REMOVE_SELECTED_SELLER: 'REMOVE_SELECTED_SELLER',
  SET_SELECTED_SELLER_FAMILY: 'SET_SELECTED_SELLER_FAMILY',
  REMOVE_SELECTED_SELLER_FAMILY: 'REMOVE_SELECTED_SELLER_FAMILY',
  SET_ALL_SELLER_FAMILIES: 'SET_ALL_SELLER_FAMILIES',
} as const

type SellerActionsKeys = keyof typeof sellerActions
type SellerActions = (typeof sellerActions)[SellerActionsKeys]

type SellerReducerAction = {
  type: SellerActions
  seller?: SellerDTO
  sellers?: Array<SellerDTO>
}

const reducer = (state: SellerReducerState, action: SellerReducerAction): SellerReducerState => {
  switch (action.type) {
    case sellerActions.SET_USER_SELLER:
      return { ...state, userSeller: action.seller as SellerReducerState['userSeller'] }

    case sellerActions.SET_SELECTED_SELLER:
      return { ...state, selectedSeller: action.seller as SellerReducerState['selectedSeller'] }

    case sellerActions.REMOVE_SELECTED_SELLER:
      return { ...state, selectedSeller: defaultSellerDTO }

    case sellerActions.SET_SELECTED_SELLER_FAMILY:
      return {
        ...state,
        selectedSellerFamily: action.seller as SellerReducerState['selectedSellerFamily'],
      }

    case sellerActions.REMOVE_SELECTED_SELLER_FAMILY:
      return {
        ...state,
        selectedSellerFamily: defaultSellerDTO,
      }

    case sellerActions.SET_ALL_SELLER_FAMILIES:
      return {
        ...state,
        allSellerFamilies: action.sellers as SellerReducerState['allSellerFamilies'],
      }

    default:
      return state
  }
}

type SellerContextProps = {
  currentSeller: SellerDTO
  currentSellerFamily: SellerReducerState['selectedSellerFamily']
  allSellerFamilies: SellerReducerState['allSellerFamilies']
  isFamilyAllSellersOptionSelected: boolean

  setUserSeller: (seller: SellerDTO) => void

  setSelectedSeller: (seller: SellerDTO) => void
  removeSelectedSeller: () => void

  setSelectedSellerFamily: (seller: SellerDTO) => void
  removeSelectedSellerFamily: () => void

  setAllSellerFamilies: (sellers: Array<SellerDTO>) => void
}

const defaultSellerContext: SellerContextProps = {
  currentSeller: defaultSellerDTO,
  currentSellerFamily: defaultSellerDTO,
  allSellerFamilies: [],
  isFamilyAllSellersOptionSelected: false,

  setUserSeller: (_seller) => {},

  setSelectedSeller: (_seller) => {},
  removeSelectedSeller: () => {},

  setSelectedSellerFamily: (_sellerFamily) => {},
  removeSelectedSellerFamily: () => {},

  setAllSellerFamilies: (_sellers) => {},
}

const SellerContext = React.createContext<SellerContextProps>(defaultSellerContext)

const SellerProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, defaultSellerReducerState)

  const { userSeller, selectedSeller, selectedSellerFamily, allSellerFamilies } = state

  const currentSeller = (() => {
    if (isSellerNotDefault(selectedSeller)) return selectedSeller
    if (isSellerNotDefault(userSeller)) return userSeller
    return defaultSellerDTO
  })()

  const currentSellerFamily = (() => {
    if (isSellerNotDefault(selectedSellerFamily)) return selectedSellerFamily
    if (isSellerNotDefault(userSeller)) return userSeller
    return defaultSellerDTO
  })()

  const isFamilyAllSellersOptionSelected: boolean =
    selectedSeller?.cardName === familyAllSellersCardName

  const providerValue = React.useMemo<SellerContextProps>(
    () => ({
      currentSeller,
      currentSellerFamily,
      allSellerFamilies: allSellerFamilies || [],
      isFamilyAllSellersOptionSelected,

      setUserSeller: (seller) => {
        dispatch({ type: 'SET_USER_SELLER', seller })
      },

      setSelectedSeller: (seller) => {
        dispatch({ type: 'SET_SELECTED_SELLER', seller })
      },
      removeSelectedSeller: () => {
        dispatch({ type: 'REMOVE_SELECTED_SELLER' })
      },

      setSelectedSellerFamily: (seller) => {
        dispatch({ type: 'SET_SELECTED_SELLER_FAMILY', seller })
      },
      removeSelectedSellerFamily: () => {
        dispatch({ type: 'REMOVE_SELECTED_SELLER_FAMILY' })
      },

      setAllSellerFamilies: (sellers) => {
        dispatch({ type: 'SET_ALL_SELLER_FAMILIES', sellers })
      },
    }),
    [allSellerFamilies, selectedSeller, selectedSellerFamily, userSeller],
  )

  return <SellerContext.Provider value={providerValue}>{children}</SellerContext.Provider>
}

const useSeller = () => React.useContext(SellerContext)

export { SellerProvider, useSeller }
