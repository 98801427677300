import React from 'react'

type WaitingFilesCountContextType = {
  waitingFilesCount: number
  setWaitingFilesCount: React.Dispatch<React.SetStateAction<number>>
}

const WaitingFilesCountContext = React.createContext<WaitingFilesCountContextType>({
  waitingFilesCount: 0,
  setWaitingFilesCount: () => {},
})

const WaitingFilesCountProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [waitingFilesCount, setWaitingFilesCount] = React.useState<number>(0)

  const providerValue = React.useMemo(
    () => ({
      waitingFilesCount,
      setWaitingFilesCount,
    }),
    [waitingFilesCount],
  )

  return (
    <WaitingFilesCountContext.Provider value={providerValue}>
      {children}
    </WaitingFilesCountContext.Provider>
  )
}

const useWaitingFilesCount = () => React.useContext(WaitingFilesCountContext)

export { WaitingFilesCountContext, WaitingFilesCountProvider, useWaitingFilesCount }
