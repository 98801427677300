import React from 'react'
import { GroupsOutlined, Person } from '@mui/icons-material'
import { useGetCurrentSeller, useGetSellersByFamily } from 'utils/hooks/queries'
import { ComboBox } from 'components/common'
import { useMsalSetup, useSeller, useWaitingFilesCount } from 'contexts'
import { SellerDTO, defaultSellerDTO, familyAllSellersCardName } from 'models'
import { AutocompleteChangeReason } from '@mui/material'
import { theme } from 'utils/theme'
import { isSellerDefault, isSellerNotDefault } from 'utils/helpers'
import { NavBarSellerFamily } from './NavBarSellerFamily'

const NavBarSellerSection = () => {
  const {
    currentSeller,
    setUserSeller,
    setSelectedSeller,
    removeSelectedSeller,
    currentSellerFamily,
    setSelectedSellerFamily,
  } = useSeller()
  const { isLoginB2C, isLoginB2B } = useMsalSetup()
  const { setWaitingFilesCount } = useWaitingFilesCount()

  const { data: currentSellerData, refetch: refetchCurrentSeller } = useGetCurrentSeller()
  const { data: currentFamilySellersData, refetch: refetchFamilySellers } = useGetSellersByFamily({
    sellerFamilyCode: currentSellerFamily?.cardCodeFamille as string,
  })

  // Add all sellers option
  const currentFamilySellersOptions = (() => {
    if (!currentFamilySellersData || currentFamilySellersData?.length === 0) {
      return []
    }
    const familyAllSellersOption: SellerDTO = {
      ...defaultSellerDTO,
      cardCodeFamille: currentSellerFamily?.cardCodeFamille ?? '',
      cardNameFamille: currentSellerFamily?.cardNameFamille ?? '',
      cardName: familyAllSellersCardName,
    }
    return [familyAllSellersOption, ...currentFamilySellersData]
  })()

  const selectedFamilySeller = currentFamilySellersOptions.find(
    (seller) => seller.cardName === currentSeller?.cardName,
  )

  const handleSellerSelection = (
    event: React.SyntheticEvent<Element, Event>,
    option: SellerDTO | null | undefined,
    reason: AutocompleteChangeReason,
  ) => {
    if (reason === 'clear') {
      removeSelectedSeller()
      setWaitingFilesCount(0)
      return
    }
    setSelectedSeller(option as SellerDTO)
  }

  React.useEffect(() => {
    if (!currentSellerData) return
    if (isLoginB2C && isSellerDefault(currentSellerFamily)) {
      setSelectedSellerFamily(currentSellerData)
    }
    if (isLoginB2C && isSellerNotDefault(currentSeller)) {
      setUserSeller(currentSellerData)
      return
    }
    if (currentSellerData.cardCode === currentSeller?.cardCode) return
    setSelectedSeller(currentSellerData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSellerData])

  React.useEffect(() => {
    if (currentSellerFamily?.cardCodeFamille) refetchFamilySellers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSellerFamily])

  React.useEffect(() => {
    if (isLoginB2C) {
      refetchCurrentSeller()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <GroupsOutlined className="ml-3" />
      {isLoginB2B ? (
        <NavBarSellerFamily />
      ) : (
        <p className="ml-2">{currentSeller?.cardNameFamille}</p>
      )}
      <Person className="ml-3" />
      {currentFamilySellersOptions.length > 0 ? (
        <ComboBox
          options={currentFamilySellersOptions}
          getOptionLabel={(option) => option?.cardName || ''}
          selectedOption={selectedFamilySeller}
          onSelection={handleSellerSelection}
          boxStyle={
            selectedFamilySeller ? undefined : { border: `3px solid ${theme.palette.primary.main}` }
          }
        />
      ) : null}
    </>
  )
}

export default NavBarSellerSection
