import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { useSeller } from 'contexts'
import { FutureSaleVehicleDTO } from 'models'
import { currentQueryService } from 'utils/api/queries'

type GetFutureSaleVehiclesParams = {
  saleId: string
  isSaleOnline: boolean
}

const useGetFutureSaleVehicles = (params: GetFutureSaleVehiclesParams) => {
  const { currentSeller, isFamilyAllSellersOptionSelected } = useSeller()

  const query = useQuery<Array<FutureSaleVehicleDTO>, Error>({
    queryKey: ['getFutureSaleVehicles', currentSeller.cardName, params.saleId],
    queryFn: () =>
      currentQueryService.getFutureSaleVehicles(currentSeller, params.saleId, params.isSaleOnline),
    enabled: false,
  })

  const { refetch } = query

  React.useEffect(() => {
    if (!isFamilyAllSellersOptionSelected && !currentSeller?.cardCode) return
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSeller])

  return query
}

export { useGetFutureSaleVehicles }
