import { useMsal } from '@azure/msal-react'
import { useMsalSetup } from 'contexts'
import { getToken } from 'utils/api'

const useToken = () => {
  const { instance } = useMsal()
  const { msalSetup } = useMsalSetup()

  return {
    getToken: () => getToken(instance, msalSetup.loginRequest),
  }
}

export default useToken
