import React from 'react'
import { VehicleSheetLabels } from 'utils/labels'
import VehicleSheetLinesGroup from '../VehicleSheetLinesGroup'

const VehicleSheetSectionCondition = () => {
  const rowOne: (keyof VehicleSheetLabels)[] = ['noteVisuelle', 'interieur', 'expertise']
  const conditionSection: (keyof VehicleSheetLabels)[] = [
    'dateDerniereCotation',
    'cotePerso',
    'coteKm',
    'age',
    'reserve',
    'prixAdjudication',
    'prixNeuf',
    'res1',
    'fre',
    'estimation',
    'pcentCoteDatee',
    'pcentCoteKm',
  ]

  return (
    <>
      <VehicleSheetLinesGroup
        arrayOfKeys={rowOne}
        className="grid content-around grid-flow-col grid-cols-3 col-span-3"
      />

      <VehicleSheetLinesGroup
        arrayOfKeys={conditionSection}
        className="grid content-around h-full grid-cols-3 col-span-3"
      />
    </>
  )
}

export default VehicleSheetSectionCondition
