/* eslint-disable camelcase */
import { DateISO8601 } from './isoDate'

/* ************************* */
/* ********  TYPES  ******** */
/* ************************* */

type FutureSaleDTO = {
  metadata: {
    type: string
    uri: string
  }
  dateVente: string
  dateVenteFR: string
  lib_Vente: string
  lib: string
  typeVente: 'Classique' | 'Electronique'
  cardCode: string
  docEntryVente: number | null
}

type FutureSaleVehicleDTO = {
  metadata: {
    type: string
    uri: string
  }
  numeroOrdre: string
  numeroOrdreComplement: string
  immatriculation: string
  genre: string
  typeComm: string
  date1MEC: DateISO8601 | null
  project: string
  reserve: number
  kilomReel: number | null
  coteKm: number | null
  cotePerso: number | null
  coteStandard: number | null
  observationsVendeur: string
  cardCode: string
  tva: string
  docEntryVente: number | null
  docEntryVenteE: number | null
  tarifVente: string | null
  tarifVenteElectronique: string | null
  couleur: string
  nbRetraitE: number | null
  nbRetrait: number | null
  estimation: number | null
  nombrePassage: number | null
  dossierRefVendeur: string
  prixRetire: number | null
  memoExterne: string
}

type FutureSaleVehicleFormatted = Omit<FutureSaleVehicleDTO, 'date1MEC'> & {
  date1MEC: string
}

type FutureSaleVehicleOverload = FutureSaleVehicleFormatted & {
  percentCP: number | null
  percentCK: number | null
}

/* ************************ */
/* ***  DEFAULT VALUES  *** */
/* ************************ */

const defaultFutureSaleDTO: FutureSaleDTO = {
  metadata: {
    type: '',
    uri: '',
  },
  dateVente: '',
  dateVenteFR: '',
  lib_Vente: '',
  lib: '',
  typeVente: 'Classique',
  cardCode: '',
  docEntryVente: null,
}

const defaultFutureSaleVehicleDTO: FutureSaleVehicleDTO = {
  numeroOrdre: '',
  immatriculation: '',
  genre: '',
  typeComm: '',
  date1MEC: null,
  kilomReel: null,
  cotePerso: null,
  coteKm: null,
  reserve: 0,
  tva: '',
  observationsVendeur: '',
  project: '',
  metadata: {
    type: '',
    uri: '',
  },
  numeroOrdreComplement: '',
  coteStandard: null,
  cardCode: '',
  docEntryVente: null,
  docEntryVenteE: null,
  tarifVente: null,
  tarifVenteElectronique: null,
  couleur: '',
  nbRetraitE: null,
  nbRetrait: null,
  estimation: null,
  nombrePassage: null,
  dossierRefVendeur: '',
  prixRetire: null,
  memoExterne: '',
}

const defaultFutureSaleVehicle: FutureSaleVehicleOverload = {
  numeroOrdre: '',
  immatriculation: '',
  genre: '',
  typeComm: '',
  date1MEC: '',
  kilomReel: null,
  cotePerso: null,
  coteKm: null,
  reserve: 0,
  tva: '',
  observationsVendeur: '',
  project: '',
  percentCP: null,
  percentCK: null,
  dossierRefVendeur: '',
  prixRetire: null,
  estimation: null,
  metadata: {
    type: '',
    uri: '',
  },
  numeroOrdreComplement: '',
  coteStandard: null,
  cardCode: '',
  docEntryVente: null,
  docEntryVenteE: null,
  tarifVente: null,
  tarifVenteElectronique: null,
  couleur: '',
  nbRetraitE: null,
  nbRetrait: null,
  nombrePassage: null,
  memoExterne: '',
}

export { defaultFutureSaleDTO, defaultFutureSaleVehicleDTO, defaultFutureSaleVehicle }
export type { FutureSaleDTO, FutureSaleVehicleDTO, FutureSaleVehicleOverload }
