import { WaitingFileVehicleDTO, defaultWaitingFile } from 'models'
import React from 'react'
import { waitingFilesTableLabels } from 'utils/labels'
import { DataTable } from 'components/dataTable'
import { useGetWaitingFiles } from 'utils/hooks/queries'
import { useAlert, useWaitingFilesCount } from 'contexts'
import { useCheckData, useLoading, useVehicleModal } from 'utils/hooks'

type WaitingFilesTableData = {
  title: string
  data: Array<WaitingFileVehicleDTO>
}

const WaitingFilesPage = () => {
  const { data: waitingFilesData, isLoading, error: getError } = useGetWaitingFiles()
  const { displayAlert } = useAlert()
  const { Loading } = useLoading()
  const { setOpenModal, VehicleModal } = useVehicleModal()
  const { dataAfterCheck, isDataEmpty } = useCheckData<WaitingFileVehicleDTO>(
    waitingFilesData,
    defaultWaitingFile,
  )
  const { setWaitingFilesCount } = useWaitingFilesCount()

  const [vehicleModalActiveId, setVehicleModalActiveId] = React.useState<string>('')

  const waitingFilesDTO = dataAfterCheck

  const waitingFilesTablesData: Array<WaitingFilesTableData> = waitingFilesDTO.reduce<
    Array<WaitingFilesTableData>
  >((prev, curr) => {
    const allStatuses = prev.map((previousTablesData) => previousTablesData.title)
    const currentStatus = curr.statut ?? ''

    if (allStatuses.includes(currentStatus)) {
      const newWaitingFilesTablesData: Array<WaitingFilesTableData> = prev.map(
        (singleStatusTableData) => {
          if (singleStatusTableData.title !== currentStatus) {
            return singleStatusTableData
          }

          const newDataTables = { ...singleStatusTableData }
          newDataTables.data.push(curr)

          return newDataTables
        },
      )

      return newWaitingFilesTablesData
    }

    const currentTableData: WaitingFilesTableData = {
      title: currentStatus ?? '',
      data: [curr],
    }

    return [...prev, currentTableData]
  }, [])

  React.useEffect(() => {
    if (getError?.message) {
      displayAlert('Erreur lors du chargement des dossiers en attente', 'error')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getError])

  React.useEffect(() => {
    if (waitingFilesData) {
      setWaitingFilesCount(waitingFilesData.length)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waitingFilesData])

  if (isLoading) return <Loading />
  if (isDataEmpty) return <p>Aucun dossier en attente.</p>
  return (
    <>
      {waitingFilesTablesData.map((statusTableRows) => {
        return (
          <div key={statusTableRows.title}>
            <DataTable
              columnsTitles={waitingFilesTableLabels}
              tableTitle={statusTableRows.title}
              data={statusTableRows.data}
              options={{
                onRowClick: (line) => {
                  const typedLine = line as unknown as WaitingFileVehicleDTO
                  setVehicleModalActiveId(typedLine.project)
                  setOpenModal(true)
                },
              }}
            />
          </div>
        )
      })}
      <VehicleModal
        id={vehicleModalActiveId}
        onClose={() => setVehicleModalActiveId('')}
      />
    </>
  )
}

export default WaitingFilesPage
