import { WaitingFileVehicleDTO } from 'models'

type WaitingFilesPropertiesToDisplay = Pick<
  Record<keyof WaitingFileVehicleDTO, string | undefined>,
  | 'sousStatut'
  | 'nbJ'
  | 'dossierRefVendeur'
  | 'immatriculation'
  | 'genre'
  | 'marque'
  | 'typeComm'
  | 'proprietaireDebiteur'
  | 'dateLivraison'
>
type WaitingFilesTableLabels = Record<keyof WaitingFilesPropertiesToDisplay, string>

const waitingFilesTableLabels: WaitingFilesTableLabels = {
  sousStatut: 'Raison',
  nbJ: 'NJ',
  dossierRefVendeur: 'Réf',
  immatriculation: 'Immat',
  genre: 'Genre',
  marque: 'Marque',
  typeComm: 'Type Commercial',
  proprietaireDebiteur: 'Propriétaire / déb',
  dateLivraison: 'Date entrée sur parc',
}

export { waitingFilesTableLabels }
