const parcStockageDictionnary: { [key: number]: string } = {
  1: 'VPAUTO LORIENT',
  2: 'VPAUTO NANTES',
  4: 'CHILLY HOTEL DES VENTES',
  5: 'VPAUTO ROUEN',
  6: 'LAUTO FARGO',
  7: 'VPAUTO LYON COLOMBIER SAUGNIEU',
  8: 'CA CONSUMER FINANCE EVRY',
  9: 'TEA NANTES',
  10: 'LIMOGES STVA',
  11: 'CAT MIRAMAS',
  12: 'CAT MOUGINS',
  13: 'SOMEDAT TOULOUSE',
  14: 'CAR ENCHERES NICE',
  15: 'CAT WAVRIN',
  16: 'CAT FORBACH',
  17: 'CAT VALENTON',
  18: 'UNIROUTE TOURS',
  19: 'CAT ASNIERES LES BOURGES',
  20: 'CAT BASSENS',
  21: 'CAT LE BOULOU',
  22: 'CAT AMBERIEU',
  23: 'TEA CORBAS',
  24: 'CAT LE CELLIER',
  25: 'CAT DIJON',
  26: 'CAT BAYONNE',
  27: 'CAT AVRIGNY',
  28: 'CAT MAGNY EN VEXIN',
  29: 'TEA BTV TOULOUSE',
  30: 'TEA FLEURY',
  31: 'AUTOMOBILES PEUGEOT CARRIERES',
  32: 'GEFCO VILLENEUVE ST GEORGES',
  33: 'TEA LYON PARC MONTMARTIN',
  34: 'BAUDRON RENNES',
  35: 'CITROEN LIMAY',
  36: 'WALON BOUCHAIN',
  37: 'GEFCO HORDAIN',
  38: 'WALON LE MEUX',
  39: 'TEA AUNAY SOUS AUNEAU',
  40: 'CAT AULNAY',
  41: 'STVA TOULOUSE FENOUILLET',
  42: 'CAT FENOUILLET',
  43: 'TAS MARSEILLE',
  44: 'WALON RIVESALTES',
  45: 'CARS DIFFUSION 87 / NISSAN LIMOGES',
  46: 'CAT PORTUGAL AGUAS DE MOURA',
  47: 'TEA LA NORVILLE',
  48: 'WALON LE MEUX',
  49: 'TEA VITROLLES',
  50: 'WALON LE HAVRE',
  51: 'WALON LAUTERBOURG',
  52: 'WALON TARNOS',
  53: 'TEA BORDEAUX',
  54: 'TEA ANDANCETTE',
  55: 'TEA FOS SUR MER',
  56: 'WALON TRAPPES',
  57: 'WALON LA CROIX ST OUEN',
  58: 'BAUDRIN ST NAZAIRE',
  59: 'SYNTAX SETUBAL',
  60: 'SATAS NEUVY',
  61: 'PEUGEOT NANTERRE',
  62: 'WALON CORBAS',
  63: 'CAT BENELUX SA',
  64: 'ALCOPA AUCTION RENNES / LA MEZIERE',
  65: 'ALCOPA AUCTION BEAUVAIS / STE GENEVIEVE',
  66: 'ALCOPA AUCTION TOURS / ESVRES SUR INDRE',
  67: 'ALCOPA AUCTION NANCY / FLEVILLE',
  68: 'ALCOPA AUCTION LYON / ST PRIEST',
  69: 'ALCOPA AUCTION MARSEILLE / VITROLLES',
  70: 'ALCOPA AUCTION SGDB',
  71: 'CAT QUINCIEUX',
  72: 'MOSOLF VATRY',
  73: 'TEA CAMBRAI',
  74: 'MDS Lyon Colombier Saugnieu',
  75: 'GEFCO NANTEUIL',
  76: 'SOTO',
  77: 'LLAGOSTA',
  78: 'SANTANDER',
  79: 'M LAGA',
  80: 'LUCENI',
  81: 'VPAUTO Espana',
  82: 'Groupe CAT (Sinatx Logsituca)',
  83: 'TRANSPORT MARIANI',
  84: 'BELCAR',
  85: 'VP AUTO LYON 2',
}

export { parcStockageDictionnary }
