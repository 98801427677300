import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { useSeller } from 'contexts'
import { WaitingFileVehicleDTO } from 'models'
import { currentQueryService } from 'utils/api/queries'

const useGetWaitingFiles = () => {
  const { currentSeller, isFamilyAllSellersOptionSelected } = useSeller()

  const query = useQuery<Array<WaitingFileVehicleDTO>, Error>({
    queryKey: ['getWaitingFiles', currentSeller.cardName],
    queryFn: () => currentQueryService.getWaitingFiles(currentSeller),
    enabled: false,
  })

  const { refetch } = query

  React.useEffect(() => {
    if (!isFamilyAllSellersOptionSelected && !currentSeller?.cardCode) return
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSeller])

  return query
}

export { useGetWaitingFiles }
