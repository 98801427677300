import React from 'react'
import { VehicleSheetLabels } from 'utils/labels'

import VehicleSheetLinesGroup from '../VehicleSheetLinesGroup'

const VehicleSheetSectionModelInfo = () => {
  const modelInfo: (keyof VehicleSheetLabels)[] = [
    'marque',
    'places',
    'typeComm',
    'modeleArgus',
    'date1MEC',
    'type',
    'kilomReel',
    'kilomDeclare',
    'couleur',
    'energie',
    'genre',
    'puissance',
    'carrosserie',
    'avaries',
    'autoEcole',
    'roueSecoursAbsente',
    'carnet',
    'batterie',
    'carteCode',
    'finGC',
    'clefs',
    'certificatImmatriculation',
    'courroie',
    'suiviCertifImmat',
  ]
  const colThree: (keyof VehicleSheetLabels)[] = [
    'equipementsPrint',
    'observationsVendeur',
    'scanCG',
    'scanCT',
    'suiviEntretien',
    'urlBE',
    'urlET',
    'etat',
    'memoExterne',
  ]

  return (
    <>
      <VehicleSheetLinesGroup
        arrayOfKeys={modelInfo}
        className="grid content-around h-full grid-cols-2 col-span-2 gap-2 "
      />

      <VehicleSheetLinesGroup
        arrayOfKeys={colThree}
        className="grid content-around grid-cols-1 col-span-1 col-start-3 "
      />
    </>
  )
}

export default VehicleSheetSectionModelInfo
