import React from 'react'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { useMsalSetup } from './MsalSetupContext'

const initializeMsalInstance = async (instance: PublicClientApplication) => {
  await instance.initialize()
}

const MSALProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const { msalSetup } = useMsalSetup()

  const msalInstance = new PublicClientApplication(msalSetup.config)
  initializeMsalInstance(msalInstance)

  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>
}

export default MSALProvider
