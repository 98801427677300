import { useQuery } from '@tanstack/react-query'
import { VehicleSheetDTO } from 'models'
import { currentQueryService } from 'utils/api/queries'

type GetVehicleParams = {
  vehicleImmat: string
}

const useGetVehicleByImmat = (params: GetVehicleParams) => {
  const query = useQuery<VehicleSheetDTO[], Error>({
    queryKey: ['getVehicleByImmat', params.vehicleImmat],
    queryFn: () => currentQueryService.getVehicleByImmat(params.vehicleImmat),
    enabled: false,
  })
  return query
}

export { useGetVehicleByImmat }
