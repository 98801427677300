import React from 'react'
import { DataTable } from 'components/dataTable'
import { useParams } from 'react-router-dom'
import { useGetSaleResultVehicles } from 'utils/hooks/queries'
import { useAlert, useSaleResult } from 'contexts'
import { useCheckData, useLoading, useVehicleModal } from 'utils/hooks'
import { saleResultVehiclesTableLabels } from 'utils/labels'
import { SaleResultVehicleDTO, defaultSaleResultVehicleDTO } from 'models'
import { generateSalesTableTitle } from 'utils/helpers'
import { formatSaleResultVehicles } from 'utils/helpers/format'

const SaleResultDetailsPage = () => {
  const { saleId } = useParams()

  const { saleResult } = useSaleResult()
  const {
    data: saleResultVehiclesData,
    isLoading,
    error: getError,
  } = useGetSaleResultVehicles({ saleId: saleId as string })
  const { Loading } = useLoading()
  const { displayAlert } = useAlert()
  const { setOpenModal, VehicleModal } = useVehicleModal()
  const { dataAfterCheck, isDataEmpty } = useCheckData<SaleResultVehicleDTO>(
    saleResultVehiclesData,
    defaultSaleResultVehicleDTO,
  )

  const [vehicleModalActiveId, setVehicleModalActiveId] = React.useState<string>('')

  const formattedSaleResultVehicles = formatSaleResultVehicles(dataAfterCheck)

  React.useEffect(() => {
    if (getError?.message) {
      displayAlert('Erreur lors du chargement des véhicules de la vente', 'error')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getError])

  if (isLoading) return <Loading />
  if (isDataEmpty) {
    return (
      <p>
        Les données ne peuvent pas être affichées, veuillez contacter{' '}
        <a
          href="mailto:support@vpauto.zendesk.com"
          className="underline underline-offset-4"
        >
          support@vpauto.zendesk.com
        </a>
      </p>
    )
  }

  return (
    <>
      <DataTable
        tableTitle={generateSalesTableTitle(saleResult, true)}
        columnsTitles={saleResultVehiclesTableLabels}
        data={formattedSaleResultVehicles}
        options={{
          onRowClick: (line) => {
            const typedLine = line as unknown as SaleResultVehicleDTO
            setVehicleModalActiveId(typedLine.project)
            setOpenModal(true)
          },
        }}
      />

      <VehicleModal
        id={vehicleModalActiveId}
        onClose={() => setVehicleModalActiveId('')}
      />
    </>
  )
}

export default SaleResultDetailsPage
