const formatTimeFromNumber = (timeValue: number | null): string => {
  if (timeValue === 0 || timeValue === null) {
    return ''
  }

  const hours = Math.min(23, Math.max(0, Math.floor(timeValue / 100)))
  const minutes = Math.min(59, Math.max(0, timeValue % 100))
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
}

export { formatTimeFromNumber }
