import React from 'react'
import { FutureSaleDTO, defaultFutureSaleDTO } from 'models'

type FutureSaleContextType = {
  futureSale: FutureSaleDTO
  setFutureSale: React.Dispatch<React.SetStateAction<FutureSaleDTO>>
}

const FutureSaleContext = React.createContext<FutureSaleContextType>({
  futureSale: defaultFutureSaleDTO,
  setFutureSale: () => {},
})

const FutureSaleProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [futureSale, setFutureSale] = React.useState<FutureSaleDTO>(defaultFutureSaleDTO)

  const providerValue = React.useMemo(
    () => ({
      futureSale,
      setFutureSale,
    }),
    [futureSale],
  )

  return <FutureSaleContext.Provider value={providerValue}>{children}</FutureSaleContext.Provider>
}

const useFutureSale = () => React.useContext(FutureSaleContext)

export { FutureSaleContext, FutureSaleProvider, useFutureSale }
