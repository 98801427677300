import React from 'react'
import { AuthenticatedUser, defaultAuthenticatedUser } from 'models'

const AuthenticatedUserContext = React.createContext({
  authenticatedUser: defaultAuthenticatedUser,
  setAuthenticatedUser: ((_authenticatedUser: AuthenticatedUser) => {}) as React.Dispatch<
    React.SetStateAction<AuthenticatedUser>
  >,
})

const AuthenticatedUserProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [authenticatedUser, setAuthenticatedUser] = React.useState(defaultAuthenticatedUser)
  const providerValue = React.useMemo(
    () => ({
      authenticatedUser,
      setAuthenticatedUser,
    }),
    [authenticatedUser],
  )

  return (
    <AuthenticatedUserContext.Provider value={providerValue}>
      {children}
    </AuthenticatedUserContext.Provider>
  )
}

const useAuthenticatedUser = () => React.useContext(AuthenticatedUserContext)

export { AuthenticatedUserContext, AuthenticatedUserProvider, useAuthenticatedUser }
