import { useMutation } from '@tanstack/react-query'
import { UpdateVehicleReserveParams, currentQueryService } from 'utils/api/queries'

const useUpdateVehicleReserve = (params: UpdateVehicleReserveParams) => {
  return useMutation({
    mutationKey: ['updateVehicleReserve', params.vehicleProject],
    mutationFn: () => currentQueryService.updateVehicleReserve(params),
  })
}

export { useUpdateVehicleReserve }
