import { Configuration, SilentRequest } from '@azure/msal-browser'
import { loginRequestB2B, loginRequestB2C, msalConfigB2B, msalConfigB2C } from 'config'
import React from 'react'
import { LOGIN_TYPE, LoginType } from 'utils/constants'
import { loginTypeCookie } from 'utils/cookies'

type MsalSetup = {
  config: Configuration
  loginRequest: Pick<SilentRequest, 'scopes'>
}

type MsalSetupContextType = {
  loginType: LoginType
  setLoginType: React.Dispatch<React.SetStateAction<LoginType>>
  isLoginB2C: boolean
  isLoginB2B: boolean
  msalSetup: MsalSetup
}

const defaultMsalSetup: MsalSetupContextType = {
  loginType: LOGIN_TYPE.B2C,
  setLoginType: () => {},
  isLoginB2C: true,
  isLoginB2B: false,
  msalSetup: {
    config: msalConfigB2C,
    loginRequest: loginRequestB2C,
  },
}

const msalSetupB2C: MsalSetup = {
  config: msalConfigB2C,
  loginRequest: loginRequestB2C,
}

const msalSetupB2B: MsalSetup = {
  config: msalConfigB2B,
  loginRequest: loginRequestB2B,
}

const MsalSetupContext = React.createContext<MsalSetupContextType>(defaultMsalSetup)

const MsalSetupProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [loginType, setLoginType] = React.useState<LoginType>(loginTypeCookie.get())

  const providerValue: MsalSetupContextType = React.useMemo((): MsalSetupContextType => {
    const isLoginB2C = loginType === LOGIN_TYPE.B2C
    const isLoginB2B = loginType === LOGIN_TYPE.B2B
    const msalSetup = isLoginB2C ? msalSetupB2C : msalSetupB2B

    return {
      loginType,
      setLoginType,
      isLoginB2C,
      isLoginB2B,
      msalSetup,
    }
  }, [loginType])

  React.useEffect(() => {
    loginTypeCookie.set(loginType)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginType])

  return <MsalSetupContext.Provider value={providerValue}>{children}</MsalSetupContext.Provider>
}

const useMsalSetup = () => React.useContext(MsalSetupContext)

export { defaultMsalSetup, MsalSetupProvider, useMsalSetup }
export type { MsalSetup, MsalSetupContextType }
