import { AuthenticatedUser, UserB2B, UserB2C } from 'models'

const formatUserB2C = (decodedToken: UserB2C): AuthenticatedUser => {
  const { emails, family_name: lastName, given_name: firstName } = decodedToken
  const userEmail = emails?.[0]
  const fullName = `${firstName} ${lastName}`

  return {
    email: userEmail,
    fullName,
  }
}

const formatUserB2B = (decodedToken: UserB2B): AuthenticatedUser => {
  const { name: fullName, unique_name: email } = decodedToken

  return {
    email,
    fullName,
  }
}

export { formatUserB2C, formatUserB2B }
