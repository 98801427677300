import { SaleResultVehicleDTO, SaleResultVehicleOverload } from 'models'

const formatSaleResultVehicles = (
  vehicles: Array<SaleResultVehicleDTO>,
): Array<SaleResultVehicleOverload> => {
  const formattedVehicles = vehicles.map((vehicle) => {
    const {
      estimation: vehicleEstimation,
      coteKm: vehicleCoteKm,
      cotePerso: vehicleCotePerso,
    } = vehicle
    const hasEstimation = Boolean(vehicleEstimation)

    if (!hasEstimation) return vehicle

    // Compute percentages
    const canComputePercentageCP = Boolean(vehicleCotePerso)
    const percentCP = canComputePercentageCP
      ? `${Math.round(
          ((vehicleEstimation as number) / (vehicleCotePerso as number)) * 100,
        ).toString()}%`
      : null

    const canComputePercentageCK = Boolean(vehicleCoteKm)
    const percentCK = canComputePercentageCK
      ? `${Math.round(
          ((vehicleEstimation as number) / (vehicleCoteKm as number)) * 100,
        ).toString()}%`
      : null

    return { ...vehicle, percentCP, percentCK }
  })

  return formattedVehicles as Array<SaleResultVehicleOverload>
}

export { formatSaleResultVehicles }
