import Cookies from 'js-cookie'
import { COOKIE_KEYS } from 'utils/constants'

const setRememberMeCookie = (isRememberMeChecked: boolean) => {
  const stringifiedCookieValue = JSON.stringify(isRememberMeChecked)
  Cookies.set(COOKIE_KEYS.REMEMBER_ME, stringifiedCookieValue, { expires: 365 })
}

const getRememberMeCookie = () => {
  const isRememberMeChecked: boolean | '' = JSON.parse(Cookies.get(COOKIE_KEYS.REMEMBER_ME) || '""')

  if (isRememberMeChecked === '') {
    setRememberMeCookie(false)
    return false
  }
  return isRememberMeChecked
}

const removeRememberMeCookie = () => {
  Cookies.remove(COOKIE_KEYS.REMEMBER_ME)
}

const rememberMeCookie = {
  get: getRememberMeCookie,
  set: setRememberMeCookie,
  remove: removeRememberMeCookie,
}

export { rememberMeCookie }
