import { msalAxiosInstance } from 'api'
import { ENDPOINTS, Endpoints } from 'utils/constants'
import { IQueries, UpdateVehicleReserveAxiosResponse } from './queryInterface'
import { queryAdapter } from './queryAdapter'

const queryAdapterMockedReserveUpdate: IQueries = {
  ...queryAdapter,
  updateVehicleReserve: async ({ vehicleProject, newReserve }) => {
    const randomRoute =
      // 0 for error, 1 for success
      Math.random() < 0.5
        ? ENDPOINTS.MOCK_UPDATE_VEHICLE_RESERVE
        : ENDPOINTS.MOCK_UPDATE_VEHICLE_RESERVE_ERROR

    const response = await msalAxiosInstance.put<
      Endpoints,
      UpdateVehicleReserveAxiosResponse,
      {
        numDossier: string
        reserve: string
      }
    >(randomRoute, {
      numDossier: vehicleProject,
      reserve: newReserve.toString(),
    })

    return response
  },
}

export { queryAdapterMockedReserveUpdate }
