import * as React from 'react'
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Outlet, useNavigate } from 'react-router-dom'
import { VPautoLogo } from 'components/logos'
import { PAGES, PATHS } from 'utils/constants'
import { rememberMeCookie } from 'utils/cookies'
import { useMsal } from '@azure/msal-react'
import { LogoutOutlined } from '@mui/icons-material'
import { useAuthenticatedUser, useMsalSetup, useReserveQueue, useSeller } from 'contexts'
import { appInsights } from 'utils/appInsights'
import { defaultSellerDTO } from 'models'
import { NavBarListItem } from './NavBarListItem'
import { NavBarSellerSection } from './NavBarSellerSection'
import { NavBarReserveStatus } from './NavBarReserveStatus'

const drawerWidth = 280

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
)

const NavBar = () => {
  const { hasTask } = useReserveQueue()

  const theme = useTheme()
  const navigate = useNavigate()
  const { instance: msalInstance } = useMsal()
  const { authenticatedUser } = useAuthenticatedUser()
  const { isLoginB2B } = useMsalSetup()
  const { currentSeller } = useSeller()

  const [open, setOpen] = React.useState<boolean>(true)

  const displayedName = authenticatedUser?.email || authenticatedUser?.fullName || 'User'
  appInsights.setAuthenticatedUserContext(displayedName)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const logout = () => {
    rememberMeCookie.remove()
    msalInstance.logout()
    navigate(PATHS.signIn)
  }

  React.useEffect(() => {
    const currentPath = window.location.pathname
    const isPathFutureSaleVehicles = currentPath.startsWith(`/${PATHS.futureSales}/`)
    const isPathSaleResultVehicles = currentPath.startsWith(`/${PATHS.salesResult}/`)

    if (isPathFutureSaleVehicles) {
      navigate(PATHS.futureSales)
    }
    if (isPathSaleResultVehicles) {
      navigate(PATHS.salesResult)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSeller])

  return (
    <Box className="flex ">
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        color="secondary"
        sx={{
          boxShadow: 'none',
          borderLeft: open ? `1px solid ${theme.palette.secondary.light}` : 'none',
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <div className="flex justify-between w-full">
            <div className="flex items-center justify-around">
              <VPautoLogo className="py-2 max-h-14" />
              <NavBarSellerSection />
            </div>
            <div className="flex items-center justify-between">
              <NavBarReserveStatus />
              <p className="mr-4">{`Bonjour ${displayedName}`}</p>
              <button
                type="button"
                onClick={logout}
                disabled={hasTask}
                className={hasTask ? 'text-gray-500' : ''}
              >
                <LogoutOutlined titleAccess="Se déconnecter" />
              </button>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.secondary.dark,
            border: 'none',
          },
        }}
      >
        <DrawerHeader
          sx={{
            backgroundColor: theme.palette.secondary.main,
          }}
        >
          <IconButton
            onClick={handleDrawerClose}
            sx={{
              color: theme.palette.secondary.contrastText,
            }}
          >
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <List sx={{ padding: 0, backgroundColor: theme.palette.secondary.dark }}>
          {Object.values(PAGES).map((page) => (
            <div key={page.text}>
              <NavBarListItem
                page={page}
                open={open}
              />
              <Divider
                sx={{
                  backgroundColor: theme.palette.secondary.main,
                }}
              />
            </div>
          ))}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3 }}
      >
        <DrawerHeader />
        {isLoginB2B && JSON.stringify(currentSeller) === JSON.stringify(defaultSellerDTO) ? (
          <p>Sélectionner une famille puis un compte dans les listes déroulantes ci-dessus</p>
        ) : (
          <Outlet />
        )}
      </Box>
    </Box>
  )
}

export default NavBar
