import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { SaleResultDTO } from 'models'
import { currentQueryService } from 'utils/api/queries'
import { useSeller } from 'contexts'

const useGetSalesResult = () => {
  const { currentSeller, isFamilyAllSellersOptionSelected } = useSeller()

  const query = useQuery<Array<SaleResultDTO>, Error>({
    queryKey: ['getSalesResult', currentSeller.cardName],
    queryFn: () => currentQueryService.getSalesResult(currentSeller),
    enabled: false,
  })

  const { refetch } = query

  React.useEffect(() => {
    if (!isFamilyAllSellersOptionSelected && !currentSeller?.cardCode) return
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSeller])

  return query
}

export { useGetSalesResult }
