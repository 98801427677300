import { SellerDTO } from 'models'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { dummyQueryAdapter } from './dummyQueryAdapter'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { queryAdapter } from './queryAdapter'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { queryAdapterMockedReserveUpdate } from './queryAdapterMockedReserveUpdate'
import { IQueries, UpdateVehicleReserveParams } from './queryInterface'

const queryService = (adapter: IQueries): IQueries => ({
  getFutureSales: (seller: SellerDTO) => adapter.getFutureSales(seller),
  getFutureSaleVehicles: (seller: SellerDTO, saleId: string, isSaleOnline: boolean) =>
    adapter.getFutureSaleVehicles(seller, saleId, isSaleOnline),
  getSalesResult: (seller: SellerDTO) => adapter.getSalesResult(seller),
  getSaleResultVehicles: (seller: SellerDTO, saleId: string) =>
    adapter.getSaleResultVehicles(seller, saleId),
  getStock: (seller: SellerDTO) => adapter.getStock(seller),
  getWaitingFiles: (seller: SellerDTO) => adapter.getWaitingFiles(seller),
  getVehicle: (vehicleId: string) => adapter.getVehicle(vehicleId),
  getVehicleByImmat: (vehicleImmat: string) => adapter.getVehicleByImmat(vehicleImmat),
  getSellersByFamily: (familyCardCode: string) => adapter.getSellersByFamily(familyCardCode),
  getCurrentSeller: () => adapter.getCurrentSeller(),
  getOneSellerFromEachFamily: () => adapter.getOneSellerFromEachFamily(),
  updateVehicleReserve: ({ vehicleProject, newReserve }: UpdateVehicleReserveParams) =>
    adapter.updateVehicleReserve({ vehicleProject, newReserve }),
  getPhotos: (immat: string) => adapter.getPhotos(immat),
})

const currentQueryService = queryService(queryAdapter)

export { queryService, currentQueryService }
