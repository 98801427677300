import { StockVehicleDTO } from 'models'

type StockVehiclePropertiesToDisplay = Pick<
  StockVehicleDTO,
  | 'nbJ'
  | 'dossierRefVendeur'
  | 'immatriculation'
  | 'genre'
  | 'marque'
  | 'typeComm'
  | 'proprietaireDebiteur'
  | 'dateCreation'
  // Date du mandat
  | 'dateLivraison'
  | 'parcStockage'
  | 'statut'
  | 'sousStatut'
  | 'certificatImmatriculation'
  | 'immatriculation2'
  | 'dateVenteFR'
>
type StockPageTableLabels = Record<keyof StockVehiclePropertiesToDisplay, string>

const stockPageTableLabels: StockPageTableLabels = {
  // assign "undefined" to property if you don't want it to appear in data table
  nbJ: 'NJ',
  dossierRefVendeur: 'Réf',
  immatriculation: 'Immat',
  genre: 'Genre',
  marque: 'Marque',
  typeComm: 'Type Commercial',
  proprietaireDebiteur: 'Propriétaire / déb',
  dateCreation: 'Date création',
  // Date du mandat
  dateLivraison: 'Date livraison',
  dateVenteFR: 'Date vente',
  parcStockage: 'Parc Stockage',
  statut: 'Statut',
  sousStatut: 'Sous statut',
  certificatImmatriculation: 'Certif. Immat.: ',
  immatriculation2: 'Immat 2',
}

export { stockPageTableLabels }
