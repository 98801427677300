import { SaleResultDTO } from 'models'

type SalesResultPropertiesToDisplay = Pick<SaleResultDTO, 'dateVenteFR' | 'lib_Vente' | 'lib'>
type SalesResultTableLabels = Record<keyof SalesResultPropertiesToDisplay, string>

const salesResultPageLabels: SalesResultTableLabels = {
  dateVenteFR: 'Date de vente',
  lib_Vente: 'Nom de la vente',
  lib: 'Nombre de véhicules',
}

export { salesResultPageLabels }
