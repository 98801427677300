/* eslint-disable max-len */
import { ReserveQueueTask, useReserveQueue } from 'contexts'
import { FutureSaleVehicleOverload, ImportedReserveInstruction, ReserveImportError } from 'models'
import { normaliseImmat } from 'utils/helpers/format'
import {
  createDuplicateErrorMessage,
  createUnmatchedErrorMessage,
} from 'utils/helpers/reserveUploadImportHelper'

type CheckSaleReserveUpdateQueue = ReserveImportError & {
  queue: Array<ReserveQueueTask>
}

type SplitSaleVehicles = {
  queue: Array<ReserveQueueTask>
  unmatchedImmats: Array<string>
  duplicatedImmats: Array<string>
}

const useCheckSaleImmats = () => {
  const { getLastSuccessfulTask } = useReserveQueue()

  const createReserveImportQueueErrorMessage = (splitSaleVehicles: SplitSaleVehicles) => {
    const { queue, duplicatedImmats, unmatchedImmats } = splitSaleVehicles

    const saleDuplicationErrorMessage = createDuplicateErrorMessage(duplicatedImmats)

    if (saleDuplicationErrorMessage) {
      return saleDuplicationErrorMessage
    }

    if (queue.length === 0) {
      const noModificationErrorMessage =
        'Le fichier ne contient aucune modification de réserve valide pour les véhicules de cette vente'
      return noModificationErrorMessage
    }

    /// The unmatched message is not an error
    const unmatchedErrorMessage = createUnmatchedErrorMessage(unmatchedImmats)

    if (unmatchedErrorMessage) {
      return unmatchedErrorMessage
    }

    return ''
  }

  const checkSaleImmats = (
    instructions: Array<ImportedReserveInstruction>,
    saleVehicles: Array<FutureSaleVehicleOverload>,
  ): CheckSaleReserveUpdateQueue => {
    const unmatchedImmats: Array<string> = []
    const duplicatedImmats: Array<string> = []

    const reserveTasksQueue = instructions.reduce<Array<ReserveQueueTask>>(
      (tasksQueue, instruction) => {
        const currentInstructionImmat = instruction.importImmat

        const matchedVehicles = saleVehicles.filter(
          (vehicle) => normaliseImmat(vehicle.immatriculation) === currentInstructionImmat,
        )

        if (matchedVehicles.length === 0) {
          unmatchedImmats.push(currentInstructionImmat)
          return tasksQueue
        }

        const isImmatDuplicatedInSale = matchedVehicles.length > 1

        if (isImmatDuplicatedInSale) {
          duplicatedImmats.push(currentInstructionImmat)
          return tasksQueue
        }

        const matchedVehicle = matchedVehicles[0]
        const matchedVehicleLastSuccessfulTask = getLastSuccessfulTask(matchedVehicle.project)
        const latestReservePrice =
          matchedVehicleLastSuccessfulTask?.reserve || matchedVehicle.reserve
        const arePricesIdentical = latestReservePrice === instruction.importReserve

        if (arePricesIdentical) {
          return tasksQueue
        }

        const task: ReserveQueueTask = {
          project: matchedVehicle.project,
          newReserve: instruction.importReserve,
          oldReserve: matchedVehicle.reserve,
          status: 'new',
        }

        return [...tasksQueue, task]
      },
      [],
    )

    const queue = reserveTasksQueue
    const allLists: SplitSaleVehicles = {
      queue,
      unmatchedImmats,
      duplicatedImmats,
    }

    const hasError = queue.length === 0 || duplicatedImmats.length > 0

    return {
      queue,
      errorMessage: createReserveImportQueueErrorMessage(allLists),
      hasError,
    }
  }

  return {
    checkSaleImmats,
  }
}

export default useCheckSaleImmats
