import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { useSeller } from 'contexts'
import { SaleResultVehicleDTO } from 'models'
import { currentQueryService } from 'utils/api/queries'

type GetSaleResultVehiclesParams = {
  saleId: string
}

const useGetSaleResultVehicles = (params: GetSaleResultVehiclesParams) => {
  const { currentSeller, isFamilyAllSellersOptionSelected } = useSeller()

  const query = useQuery<Array<SaleResultVehicleDTO>, Error>({
    queryKey: ['getSaleResultVehicles', currentSeller.cardName, params.saleId],
    queryFn: () => currentQueryService.getSaleResultVehicles(currentSeller, params.saleId),
    enabled: false,
  })

  const { refetch } = query

  React.useEffect(() => {
    if (!isFamilyAllSellersOptionSelected && !currentSeller?.cardCode) return
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSeller])

  return query
}

export { useGetSaleResultVehicles }
