import { useQuery } from '@tanstack/react-query'
import { SellerDTO } from 'models'
import { currentQueryService } from 'utils/api/queries'

const useGetOneSellerFromEachFamily = () => {
  return useQuery<Array<SellerDTO>, Error>({
    queryKey: ['getOneSellerFromEachFamily'],
    queryFn: () => currentQueryService.getOneSellerFromEachFamily(),
  })
}

export { useGetOneSellerFromEachFamily }
