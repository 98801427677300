import { DateISO8601 } from './isoDate'

/* eslint-disable camelcase */
type StockVehicleDTO = {
  metadata: {
    type: string
    uri: string
  }
  project: string
  dossierRefVendeur: string
  immatriculation: string
  immatriculation2: string
  statut: string | null
  sousStatut: string | null
  parcStockage: string
  genre: string
  marque: string
  typeComm: string
  nbJ: string
  stock: string
  proprietaireDebiteur: string
  certificatImmatriculation: string
  cardCode: string
  u_W3C_SFEI: string
  dateLivraison: DateISO8601 | null
  dateCreation: DateISO8601 | null
  search: string
  dateVente: string
  dateVenteFR: string
}

const defaultStockVehicle: StockVehicleDTO = {
  dossierRefVendeur: '',
  immatriculation: '',
  parcStockage: '',
  genre: '',
  marque: '',
  typeComm: '',
  statut: '',
  sousStatut: '',
  nbJ: '',
  immatriculation2: '',
  proprietaireDebiteur: '',
  certificatImmatriculation: '',
  dateLivraison: null,
  dateCreation: null,
  metadata: {
    type: '',
    uri: '',
  },
  project: '',
  stock: '',
  cardCode: '',
  u_W3C_SFEI: '',
  search: '',
  dateVente: '',
  dateVenteFR: '',
}

export { defaultStockVehicle }
export type { StockVehicleDTO }
