import React from 'react'
import { Alert, AlertColor, Snackbar } from '@mui/material'

type AlertSeverity = AlertColor

type SnackbarAlertContext = {
  displayAlert: (_message: string, severity: AlertSeverity) => void
}

const AlertContext = React.createContext<SnackbarAlertContext>({
  displayAlert: () => {},
})

type SnackbarAlertProps = {
  open: boolean
  message: string
  severity: AlertSeverity
  onClose: () => void
}

const SnackbarAlert: React.FC<SnackbarAlertProps> = ({ open, message, severity, onClose }) => {
  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={6000}
      onClose={onClose}
    >
      <Alert
        severity={severity}
        sx={{ width: '100%', whiteSpace: 'pre-line' }}
        onClose={onClose}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}

const AlertProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [openAlert, setOpenAlert] = React.useState<boolean>(false)
  const [alertMessage, setAlertMessage] = React.useState<string>('')
  const [alertSeverity, setAlertSeverity] = React.useState<AlertSeverity>('info')

  const handleCloseToast = (_event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setOpenAlert(false)
  }

  const displayAlert = (message: string, severity: AlertSeverity) => {
    setOpenAlert(true)
    setAlertMessage(message)
    setAlertSeverity(severity)
  }

  const alertProviderValue = React.useMemo(
    () => ({
      displayAlert,
    }),
    [],
  )

  return (
    <AlertContext.Provider value={alertProviderValue}>
      <SnackbarAlert
        open={openAlert}
        onClose={handleCloseToast}
        message={alertMessage}
        severity={alertSeverity}
      />
      {children}
    </AlertContext.Provider>
  )
}

const useAlert = () => React.useContext(AlertContext)

export { AlertProvider, useAlert, SnackbarAlert }
