import { Configuration, SilentRequest } from '@azure/msal-browser'
import { msalCommonConfig } from './msalCommonConfig'

const msalConfigB2C: Configuration = {
  auth: {
    ...msalCommonConfig.auth,
    clientId: process.env.REACT_APP_CLIENT_ID ?? '',
    authority: `https://${process.env.REACT_APP_TENANT}.b2clogin.com/${process.env.REACT_APP_TENANT}.onmicrosoft.com/${process.env.REACT_APP_POLICY}`,
    knownAuthorities: [`${process.env.REACT_APP_TENANT}.b2clogin.com`],
  },
}

const loginRequestB2C: Pick<SilentRequest, 'scopes'> = {
  scopes: [
    `https://${process.env.REACT_APP_TENANT}.onmicrosoft.com/${process.env.REACT_APP_CLIENT_ID}/default`,
  ],
}

export { msalConfigB2C, loginRequestB2C }
