/* eslint-disable max-len */
import React from 'react'
import { useFutureSaleVehicles, useReserveQueue, useAlert } from 'contexts'
import { ImportedReserveInstruction } from 'models'
import {
  parseCsvString,
  checkHeadersForImmat,
  checkHeadersForReserve,
  standardizeParsedCSV,
  checkImmatDuplicates,
} from 'utils/helpers/reserveUploadImportHelper'
import { useCheckSaleImmats } from 'utils/hooks'
import * as XLSX from 'xlsx'

const useDataTableUploadButton = () => {
  /// get data from context
  const saleVehicles = useFutureSaleVehicles()
  const { addTasks, hasTask } = useReserveQueue()
  const { displayAlert } = useAlert()
  const { checkSaleImmats } = useCheckSaleImmats()

  const [file, setFile] = React.useState<File | null>()
  const [importArray, setImportArray] = React.useState<ImportedReserveInstruction[]>([])

  const isImportingFileRef = React.useRef(false)

  const fileReader = new FileReader()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files && e.target.files[0]
    setFile(selectedFile)
    if (e.target) {
      e.target.value = ''
    }
  }

  const csvFileToArray = (csvString: string) => {
    // Make the whole string lower case, turn it into an array of strings => Like a matrix
    const parsedCSV = parseCsvString(csvString)

    // Isolate headers
    const csvHeaders = parsedCSV[0]

    const immatErrors = checkHeadersForImmat(csvHeaders)
    if (immatErrors.hasError) {
      displayAlert(immatErrors.errorMessage, 'error')
      return
    }

    const reserveErrors = checkHeadersForReserve(csvHeaders)
    if (reserveErrors.hasError) {
      displayAlert(reserveErrors.errorMessage, 'error')
      return
    }

    const standardizedParsedCSV = standardizeParsedCSV(parsedCSV)

    isImportingFileRef.current = true
    setImportArray(standardizedParsedCSV)
  }

  const readFile = (fileToRead: File) => {
    const fileExtension = fileToRead?.name.split('.').reverse()[0]

    switch (fileExtension) {
      case 'csv': {
        fileReader.onload = (event) => {
          const csvOutput = event.target?.result
          csvFileToArray(csvOutput as string)
        }
        fileReader.readAsText(fileToRead)
        break
      }
      case 'xlsx':
      case 'xls': {
        fileReader.onload = (event) => {
          const binaryData = event.target?.result
          const workbook = XLSX.read(binaryData, { type: 'binary' })

          if (workbook.SheetNames.length > 1) {
            displayAlert(`Veuillez sélectionner un fichier excel avec une seule feuille`, 'error')
            return
          }
          const firstSheetName = workbook.SheetNames[0]
          const sheet = workbook.Sheets[firstSheetName]
          const csvData = XLSX.utils.sheet_to_csv(sheet, { FS: ';' })
          csvFileToArray(csvData as string)
        }
        fileReader.readAsBinaryString(fileToRead)
        break
      }
      default: {
        displayAlert(
          `Format de fichier incorrect, veuillez sélectionner un ficher csv, xls ou xlsx`,
          'error',
        )
      }
    }
  }

  React.useEffect(() => {
    if (!isImportingFileRef.current) return

    if (importArray.length === 0) {
      displayAlert('Le fichier ne contient aucune donnée', 'error')
      return
    }

    /// Search for invalid values (stop process if found)
    const hasInvalidReservePrices = importArray.some((line) => {
      const reserveValue = line.importReserve
      return Number.isNaN(reserveValue) || reserveValue < 0
    })

    if (hasInvalidReservePrices) {
      displayAlert(
        'Attention, au moins un des prix du fichier est invalide! Les prix de réserves doivent être des nombres entiers supérieurs à zéro',
        'error',
      )
      return
    }

    /// Search for at least one positive reserve price
    const reserveInstructionsPositivePrices = importArray.filter((line) => line.importReserve !== 0)

    if (reserveInstructionsPositivePrices.length === 0) {
      displayAlert(
        'La colonne "réserve" du fichier ne contient aucun nombre supérieur à zéro',
        'error',
      )
      return
    }

    // Search for duplicate immatriculations
    const duplicateError = checkImmatDuplicates(reserveInstructionsPositivePrices)
    if (duplicateError.hasError) {
      displayAlert(duplicateError.errorMessage, 'error')
      return
    }

    // Make sure there is one vehicle per immat in the sale
    const reserveUpdateQueue = checkSaleImmats(reserveInstructionsPositivePrices, saleVehicles)

    if (reserveUpdateQueue.hasError) {
      displayAlert(reserveUpdateQueue.errorMessage, 'error')
      return
    }

    /// Display unmatched message which is not an error anymore
    if (reserveUpdateQueue.errorMessage) {
      displayAlert(reserveUpdateQueue.errorMessage, 'info')
    }

    addTasks(reserveUpdateQueue.queue)
  }, [importArray])

  React.useEffect(() => {
    if (!file) return
    readFile(file)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file])

  return {
    hasTask,
    handleChange,
  }
}

export default useDataTableUploadButton
