import React from 'react'
import { VehicleSheetLabels } from 'utils/labels'
import VehicleSheetLinesGroup from '../VehicleSheetLinesGroup'

const VehicleSheetSectionInternalAdmin = () => {
  const internalAdminDisplayedItems: (keyof VehicleSheetLabels)[] = [
    /// col 1
    'project',
    'cardName',
    'statut',
    'sousStatut',
    'dossierRefVendeur',
    'immatriculation',
    'nbJoursStock',
    /// col 2
    'numeroDeDecompte',
    'numeroOrdre',
    'dateMission',
    'dateLivraison',
    'codeParcStockage',
    'dateVenteAffectee',
    'dateVenteAffecteeE',
  ]
  const judiciaryDisplayedItems: (keyof VehicleSheetLabels)[] = [
    /// col 3
    'opposition',
    'procedure',
    'tribunal',
    'mandataire',
    'jugement',
    'juge',
    /// col 4
    'commentaire',
    'denonce',
    'proprietaireDebiteur',
    'coDebiteur',
    'caution',
    'caution1',
  ]

  return (
    <>
      <VehicleSheetLinesGroup
        arrayOfKeys={internalAdminDisplayedItems}
        className="grid content-around h-full grid-flow-col grid-cols-2 col-span-2 grid-rows-7 "
      />
      <VehicleSheetLinesGroup
        arrayOfKeys={judiciaryDisplayedItems}
        className="grid content-around h-full grid-flow-col grid-cols-2 col-span-2 grid-rows-7 "
      />
    </>
  )
}

export default VehicleSheetSectionInternalAdmin
