import React from 'react'
import { useVehicle } from 'contexts'
import './style.css'
import ImageGallery from 'react-image-gallery'
import { useGetPhotos } from 'utils/hooks/queries'

const VehicleSheetSectionCarousel = ({
  isCarouselFull,
  setIsCarouselFull,
}: {
  isCarouselFull: boolean
  setIsCarouselFull: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const [isCarouselFullScreen, setIsCarouselFullScreen] = React.useState(false)
  const [currentIndex, setCurrentIndex] = React.useState(0)
  const refGallery = React.useRef<ImageGallery | null>(null)
  const currentVehicle = useVehicle()
  const picturesArray = useGetPhotos(currentVehicle.immatriculation)
  const hasAnyPhoto = picturesArray.length > 0

  const setBool = (value: boolean) => {
    setIsCarouselFull(value)
  }
  const toggleCarouselFullScreen = () => {
    setIsCarouselFullScreen(!isCarouselFullScreen)
    setBool(!isCarouselFull)
  }

  const images = picturesArray.map((imgUrl) => {
    return {
      original: imgUrl,
      thumbnail: imgUrl,
      originalClass: isCarouselFullScreen
        ? 'image-gallery-slide-fullScreen'
        : 'image-gallery-slide-smallScreen',
    }
  })

  const upDateIndex = () => {
    setCurrentIndex(refGallery.current?.getCurrentIndex() ?? 0)
  }
  const handleScreenChange = () => {
    upDateIndex()
    toggleCarouselFullScreen()
  }

  const handleClick = () => {
    upDateIndex()
    window.open(picturesArray[currentIndex], '_blank')
  }

  return hasAnyPhoto ? (
    <ImageGallery
      items={images}
      useBrowserFullscreen={false}
      showPlayButton={false}
      onScreenChange={handleScreenChange}
      ref={refGallery}
      showIndex
      startIndex={currentIndex}
      onClick={handleClick}
      onMouseOver={upDateIndex}
    />
  ) : (
    <div className="flex flex-wrap items-center justify-center w-full h-full text-xl shadow-xl rounded-xl">
      Ce véhicule n&apos;a pas (encore) de photos
    </div>
  )
}

export default VehicleSheetSectionCarousel
