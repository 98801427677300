import { NavBar } from 'components'
import React from 'react'

const SideNavBarLayout = () => (
  <div className="flex">
    <NavBar />
  </div>
)

export default SideNavBarLayout
