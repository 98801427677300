import {
  IPublicClientApplication,
  InteractionRequiredAuthError,
  PublicClientApplication,
  SilentRequest,
} from '@azure/msal-browser'

type TokenAndError = [string, { [s: string]: unknown } | ArrayLike<unknown>]

const getTokenSilent = async (
  instance: PublicClientApplication | IPublicClientApplication,
  loginRequest: Pick<SilentRequest, 'scopes'>,
): Promise<TokenAndError> => {
  const tokenAndError = ['', {}]
  // added undefined because of expected type in the request
  const account = instance.getAllAccounts()[0] ?? undefined
  const acquireTokenRequest = {
    ...loginRequest,
    account,
  }

  try {
    const response = await instance.acquireTokenSilent(acquireTokenRequest)
    tokenAndError[0] = response.accessToken
  } catch (error) {
    tokenAndError[1] = error as InteractionRequiredAuthError
    console.error('SILENT REQUEST ERROR', error)
  }

  return tokenAndError as TokenAndError
}

const getToken = async (
  instance: PublicClientApplication | IPublicClientApplication,
  loginRequest: Pick<SilentRequest, 'scopes'>,
): Promise<string> => {
  const [silentToken, silentError] = await getTokenSilent(instance, loginRequest)
  const hasSilentError = Object.values(silentError).length > 0

  if (hasSilentError) {
    console.error(silentError)
    return ''
  }

  return silentToken
}

export { getToken }
