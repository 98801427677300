import React from 'react'
import { routes } from 'Routes'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { AppProvider, useAuthenticatedUser, useMsalSetup } from 'contexts'
import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react'
import { LOCAL_STORAGE_KEYS } from 'utils/constants'
import { InteractionStatus } from '@azure/msal-browser'
import jwtDecode from 'jwt-decode'
import { useLoading, useToken } from 'utils/hooks'
import { appInsights } from 'utils/appInsights'
import { formatUserB2B, formatUserB2C } from 'utils/helpers/format'
import { UserB2B, UserB2C } from 'models'

appInsights.loadAppInsights()

const router = createBrowserRouter(routes)

const App: React.FC = () => {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const isAuthenticated = useIsAuthenticated()
  const { isLoginB2C } = useMsalSetup()

  const { getToken } = useToken()
  const { setAuthenticatedUser } = useAuthenticatedUser()

  const { isLoading, setIsLoading, Loading } = useLoading()

  const currentUrl = window.location.href

  if (
    inProgress !== InteractionStatus.None &&
    !isAuthenticated &&
    localStorage.getItem(LOCAL_STORAGE_KEYS.AFTER_SIGN_IN_URL) === null
  ) {
    const currentPath = currentUrl?.replace(window.location.origin, '')
    localStorage.setItem(LOCAL_STORAGE_KEYS.AFTER_SIGN_IN_URL, currentPath)
  }

  const checkUserAuthentication = async () => {
    setIsLoading(true)
    const token: string = await getToken()

    if (token) {
      const decodedToken = jwtDecode(token)

      const formattedUser = isLoginB2C
        ? formatUserB2C(decodedToken as UserB2C)
        : formatUserB2B(decodedToken as UserB2B)

      setAuthenticatedUser(formattedUser)
    }
    setIsLoading(false)
  }

  React.useEffect(() => {
    checkUserAuthentication()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, instance])

  React.useEffect(() => {
    return () => localStorage.removeItem(LOCAL_STORAGE_KEYS.AFTER_SIGN_IN_URL)
  }, [])

  return isLoading ? (
    <Loading />
  ) : (
    <AppProvider>
      <RouterProvider router={router} />
    </AppProvider>
  )
}

export default App
