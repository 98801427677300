import { useQuery } from '@tanstack/react-query'
import { SellerDTO } from 'models'
import { currentQueryService } from 'utils/api/queries'

const useGetCurrentSeller = () => {
  return useQuery<SellerDTO, Error>({
    queryKey: ['getCurrentSeller'],
    queryFn: () => currentQueryService.getCurrentSeller(),
    enabled: false,
  })
}

export { useGetCurrentSeller }
